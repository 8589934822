<div class="header-background pb-5">
  <img [src]="'/assets/background-1.png'" alt="" />
</div>

<div class="container-fluide p-0 m-0 px-md-4 px-3">
  <div class="row m-0">
    <div class="d-flex w-100">
      <div class="pt-1 px-1 d-none d-md-block">
        <a (click)="back($event)">
          <i class="cursor-pointer text-large bi bi-arrow-left-short"></i>
        </a>
      </div>
      <div class="w-100">
        <div class="d-flex">
          <h2 class="color-grey-3">Site Questions</h2>
          <h2 class="mx-2 font-weight-bold">{{site?.name}}</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluide p-0 m-0 px-md-5 px-3 pb-5">
  <div class="row mb-3">
    <div class="col-12 col-md-6 px-0">
      <global-search-input (sendSearch)="searchQuestion($event)"></global-search-input>
    </div>
  </div>
  <main-grid [actionTemplate]="edit"  [actionTemplatem]="edit" [gridContent]="questions" [authClick]="isAuthorized"></main-grid>
</div>

<ng-template #edit>
  <div class="d-flex justify-around-start align-items-start">
    <img src="../../../../../assets/edit.svg" alt="" />
    Edit
  </div>
</ng-template>
