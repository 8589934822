import { Base } from './Base';
import { Division } from './division';
import { Site } from './site';
import { Permission } from './permission';
import { Pdf } from './pdf';

export class User extends Base {
  id: number;
  name: string;
  role: string;
  email: string;
  division: Division[];
  sites: Site[];
  permissions:Permission;
  expirePermission:Date;

  constructor(args: Partial<User>) {
    super(args);
  }
}

export class UserDto extends Base {
  id: number;
  name: string;
  role: string;
  email: string;
  division: number[];
  sites: number[];
  permissions:Permission;

  constructor(args: Partial<User>) {
    super(args);
  }
}
