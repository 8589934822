<mat-form-field class="example-chip-list" >
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let e of email" [selectable]="selectable"
             [removable]="removable" (removed)="remove(e)">
      {{e.email}}
      <button matChipRemove *ngIf="removable">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input placeholder=""
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
</mat-form-field>
