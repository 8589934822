import { BrowserModule } from '@angular/platform-browser';
import { Component, NgModule, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';

import { GlobalComponentsModule } from './globals/components/components.module';
import { AuthModule } from './modules/auth/auth.module';
import { MainModule } from './modules/main/main.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AngularFireModule} from "@angular/fire/compat";
// import { AngularFireAnalytics, AngularFireAnalyticsModule } from "@angular/fire/compat/analytics"
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent  } from "firebase/analytics";



const app = initializeApp(environment.firebase);
const analytics =  getAnalytics(app);

@Component({
  selector: 'app-root',
  template: `
    <div
      dir="{{ 'cssDirection' | translate }}"
      class="h-100 {{ 'cssDirection' | translate }}"
    >
      <router-outlet></router-outlet>
      <app-loader></app-loader>
    </div>
  `,
  styles: ['.rtl { direction: rtl; text-align: right; }'],
})
export class AppComponent implements OnInit {
  title = 'smartQ'
  constructor(public translate: TranslateService,
    // private analytics: AngularFireAnalytics
    ) {
    let lang = localStorage.getItem('lang');
    if (environment.languages.indexOf(lang) < 0) {
      lang = environment.languages[0];
      localStorage.setItem('lang', lang);
    }
    translate.setDefaultLang(lang);
  }
  ngOnInit(): void {
    logEvent(analytics,'smartQ notification_received');
  }
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    GlobalComponentsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule,
    MainModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule
  ],
  declarations: [AppComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
