import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { UsersService } from 'src/app/globals/services/users.service';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminGuard implements CanActivate {
  constructor(private userService: UsersService) {}
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const user = await this.userService.getCurrentUser();
      return new Promise((resolve: Function, reject: Function) => {
        if (user.permissions.super_admin) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    } catch (err) {
      console.log('user', err);
    }
  }
}
