<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div class="d-md-none"></div>
      <div class="">{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <div class="row col-md-9">
      The document you are exporting has a number of questions with:
    </div>
    <ng-container>
      <ul class="col-md-6 list-container">
        <li *ngFor="let answer of data.extraData.answers">
          <h3 class="answer">{{answer}}</h3>
        </li>
      </ul>
    </ng-container>

    <div class="row justify-content-start mb-3">
      <button
        (click)="data?.extraData.onContinue()"
        mat-button
        color="primary"
        class="
          btn
          primary
          text-normal
          px-5
          col-12 col-md-4
          mt-5 mt-md-0
          m-1
          w-25
        "
      >
        Continue
      </button>
      <button
        (click)="onNoClick()"
        mat-stroked-button
        color="basic"
        class="btn border-primary text-normal px-5 col-12 col-md-4 mt-5 mt-md-0 m-1 w-25"
      >
        Show me
      </button>
    </div>
    <div class="row">
      If you choose to continue the questions will be removed from the document
    </div>
  </div>
</global-dialog-frame>
