<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <global-page-header
    class="
      d-flex
      justify-content-between
      flex-column flex-md-row
      align-md-items-center
      mb-4 mb-md-5
    "
  >
    <div class="col-6 font-weight-bold text-large mb-4 m-md-0" title *ngIf="!inputChapter">
      Chapter {{id}} - {{title}}
    </div>
    <div class="col-6 font-weight-bold text-large mb-4 m-md-0" title *ngIf="inputChapter">
      Chapter {{currentChapter?.id}} 
    </div>
    <div
      class="
        col-12 col-md-6
        d-flex
        justify-content-start justify-content-md-end
        p-md-0
        px-md-3
      "
      btnTitle
    >
      <button
        (click)="openAddSubChapter()"
        mat-button
        color="primary"
        class="text-normal mr-3 mx-md-3 btn primary"
      >
        <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
        Add Sub Chapter
      </button>
      <button
        (click)="openEditChapter()"
        mat-stroked-button
        color="primary"
        class="text-normal mr-3 mx-md-3 btn border-primary"
      >
        <img src="../../../../../../assets/edit-pen.svg" alt="" />
        <span class="ml-2"> Edit Chapter</span>
      </button>
    </div>
  </global-page-header>
  <div class="row mb-4 mb-md-5">
    <div class="col-12 mb-0 title">
      <span class="weight-500 text-normal color-grey-3"> Questions </span>
    </div>
    <div class="col-12">
      <main-sort-grid [isPress]="true" (handlePress)="goToQuestions($event)"  (handleSort)="sort()" [items]="questions"></main-sort-grid>
    </div>
  </div>

  <div class="row">
    <div class="col-12 ml-4 mb-0 title">
      <span class="weight-500 text-normal color-grey-3"> Sub-Chapters </span>
    </div>
    <div class="col-12">
      <main-sort-grid (handleSort)="sortSubChapters()" [items]="subChapters"></main-sort-grid>
    </div>
  </div>
</div>

