import { Division, DivisionDto } from 'src/app/globals/base/division';
import { DivisionService } from './../../../../../globals/services/division.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { GridContent } from '../../../components/grid/grid.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogData } from '../../products/product/product.component';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SuccessDialogComponent, SuccessDialogModel } from '../../dialog-general/SuccessDialogComponent';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../dialog-general/ConfirmDialogComponent';
import { PermissionsService } from 'src/app/globals/services/permissions.service';
import { GUARDS } from 'src/app/modules/guard/masterGuards';

@Component({
  selector: 'app-divisions',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.scss'],
})
export class DivisionsComponent implements OnInit {
  constructor(
    private location: Location,
    private router: Router,
    public dialog: MatDialog,
    private divisionService: DivisionService,
    private permissionsService: PermissionsService

  ) {
    localStorage.removeItem('site');
  }

  gridContent: GridContent;
  editDivision: FormGroup;
  addDivision: FormGroup;
  dialogs: DialogData[];
  searchArray: any[];
  productsArray: any[];
  divisions: Division[] = [];
  currentDivision: Division;
  allDivision;
  deleteMode: boolean;
  result: string = '';
  isLinkToSite:boolean = false;
  isAuthorized:boolean = false;

  ngOnInit(): void {
    this.update();
  }

  async update() {
    try {
      await this.getAllDivisions();
      this.setForms();
      this.setAuthorized();
      this.setDialogs();
      this.setQuestions();
    } catch (err) {
      throw new Error(err);
    }
  }

  async setAuthorized(){
    this.isAuthorized = await this.permissionsService.isAuthorized([GUARDS.SuperAdminGuard, GUARDS.DivisionManagerGuard]);
  }

  async getAllDivisions() {
    try {
      this.divisions = await this.divisionService.getAll();
    } catch (error) {
      console.log(error);
    }
  }

  private setDialogs() {
    this.dialogs = [
      {
        name: 'Edit Division',
        form: this.editDivision,
        mode: 'view',
        onSubmit: this.putDivision.bind(this),
        extraData: {
          changeMode: this.delete.bind(this),
          invalidName: false,
        },
      },
      {
        name: 'Add Division',
        form: this.addDivision,
        mode: 'edit',
        onSubmit: this.saveDivision.bind(this),
        extraData: {
          invalidName: false,
        },
      },
    ];
  }

  async delete(status) {
    this.deleteMode = status;
    if (this.deleteMode) {
      const message = `Are you sure you want to delete ${this.currentDivision.name} division?`;

      const dialogData = new ConfirmDialogModel('Delete Division', message);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        this.result = dialogResult;
        if (this.result) {
          try {
            await this.divisionService.delete(this.currentDivision.id);
            this.closeDialog();
            const message = `The division is deleted successfully`;
            const dialogData = new SuccessDialogModel('Success!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
            await this.update();
          } catch (err) {
            const message = `You can not delete the division, this division is linked to other elements`;
            const dialogData = new SuccessDialogModel('Error!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
          }
        }
      });
    }
  }

  private setForms() {
    this.editDivision = new FormGroup({
      answer: new FormControl({ value: '0', disabled: true }, [
        Validators.required,
      ]),
      note: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9$@$!%*?&#^-_. +]+$'),
      ]),
      link: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      answer_validity: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
    });
    this.addDivision = new FormGroup({
      answer: new FormControl({ value: '0', disabled: false }, [
        Validators.required,
      ]),
      note: new FormControl({ value: null, disabled: false }, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9$@$!%*?&#^-_. +]+$'),
      ]),
      link: new FormControl({ value: null, disabled: false }, [
        Validators.required,
      ]),
      answer_validity: new FormControl({ value: null, disabled: false }, [
        Validators.required,
      ]),
    });
  }

  goToSite(this){
    this.isLinkToSite =true;
  }

  openEditDivision(d): void {

    this.currentDivision = d;
    if(this.isLinkToSite){
      // 
      this.isLinkToSite = !this.isLinkToSite;
      localStorage.setItem('site', JSON.stringify(this.currentDivision));

      this.router.navigate(['/sites'],{ state: { division: this.currentDivision } });
      return
    }
    this.dialogs[0].mode = 'view';
    this.editDivisionForm(d);
    const dialogRef = this.dialog.open(DialogEditDivision, {
      minWidth: '350px',
      data: this.dialogs[0],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('DialogEditDivision');
    });
  }

  searchDivision(text) {
    let searchResults = this.allDivision.filter((row) =>
      row.cols[0].content.toLowerCase().includes(text.toLowerCase())
    );
    this.gridContent.rows = searchResults;
  }

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }

  private setQuestions() {
    this.gridContent = {
      header: [
        {
          title: 'Division Name',
          col: 8,
        },
        {
          title: 'Site Amount',
          col: 3,
        },
        {
          col: 1,
          title: '',
        },
      ],
      rows: [],
    };

    // const { divisions } = this;
    this.divisions?.map((d) =>
      this.gridContent.rows.push({
        action: this.openEditDivision.bind(this, d),
        cols: [
          {
            content: d.name,
            col: 8,
            weight: 400,
          },
          {
            content: d.sites.length + ' sites',
            col: 3,
            weight: 400,
          },
          {
            key: '',
            content: 'action',
            col: 1,
            weight: 300,
          },
        ],
      })
    );
    this.allDivision = [...this.gridContent.rows];
  }

  async putDivision(dialog: DialogData) {
    if (!this.deleteMode) {
      if (dialog.mode === 'edit') {
        if (!dialog.form.get('note').invalid) {
          dialog.name = 'view';
          console.log(dialog.mode);
          let d = this.divisions.find((d) => d.id === this.currentDivision.id);

          try {
            const divisionDto: DivisionDto = new DivisionDto({
              id: d.id,
              name: dialog.form.value.note,
              pdf_text: dialog.form.value.link,
              products: d.products?.map((prod) => prod),
              sites: d.sites?.map((s) => s),
              pdfs: d.pdfs,
            });
            await this.divisionService.edit(
              this.currentDivision.id,
              divisionDto
            );
            this.closeDialog();
            this.update();
          } catch (error) {
            throw error;
          }
        }
        if (dialog.form.get('note').invalid) {
          this.dialogs[0].extraData.invalidName = true;
          return;
        }
      } else {
        dialog.mode = 'edit';
        dialog.form.enable();
        console.log(dialog);
      }
    }
  }

  openAddDivision() {
    this.setForms();
    this.dialogs[1].form = this.addDivision;
    this.dialogs[1].extraData.invalidName = false;
    const dialogRef = this.dialog.open(DialogEditDivision, {
      minWidth: '350px',
      data: this.dialogs[1],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('DialogEditSite');
    });
  }

  private editDivisionForm(d: DivisionDto) {
    this.dialogs[0].form = new FormGroup({
      // answer: new FormControl({ value: '0', disabled: true }, [
      //   Validators.required,
      // ]),
      note: new FormControl({ value: d.name, disabled: true }, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9$@$!%*?&#^-_. +]+$'),
      ]),
      link: new FormControl({ value: d.pdf_text, disabled: true }, [
        Validators.required,
      ]),
      // answer_validity: new FormControl({ value: null, disabled: true }, [
      //   Validators.required,
      // ]),
    });
  }

  async saveDivision(dialog: DialogData) {
    if (dialog.mode === 'view') {
    } else {
      if (!dialog.form.get('note').invalid) {
        await this.addNewDivision(dialog);
      }
      if (dialog.form.get('note').invalid) {
        this.dialogs[1].extraData.invalidName = true;
      }
    }
  }

  private async addNewDivision(dialog: DialogData) {
    try {
      const division: Partial<DivisionDto> = {
        name: dialog.form.value.note,
        pdf_text: dialog.form.value.link,
        // products: Product[1],
        // sites: Site[1],
        // pdfs: Pdf[1],
      };
      await this.divisionService.add(division);

      this.closeDialog();
      await this.update();

      const message = `The division was created successfully`;
      const dialogData = new SuccessDialogModel('Success!', message);
      this.dialog.open(SuccessDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });
    } catch (err) {
      this.closeDialog();
      await this.update();

      const message = `An error occurred while creating the division`;
      const dialogData = new SuccessDialogModel('Error!', message);
      this.dialog.open(SuccessDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });
    }
  }

  nextStage() {
    this.router.navigateByUrl('/products/45/stage-five');
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  async del(event) {
    event.stopPropagation();
    // await this.divisionService.delete(10);
    // this.closeDialog();
    // await this.update();
  }
}

@Component({
  selector: 'dialog-edit-division',
  templateUrl: '../dialogs/dialog-edit-division.html',
})
export class DialogEditDivision {
  result: string = '';
  isEdit = false;

  constructor(
    public dialogRef: MatDialogRef<DialogEditDivision>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.disableClose = true;

    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onNoClick());
  }

  onEdit(): void {
    this.isEdit = true
  }

  onNoClick(): void {
    if(!this.isEdit){
      this.dialogRef.close(true);
      return;
    }
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      this.result = dialogResult;
      if (this.result) {
        this.dialogRef.close(true);
      }
    });
  }
}
