// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  languages: ['en'],
  //Local
  // baseUrl: 'http://localhost:3000',
  //DEV
  // baseUrl: 'https://api.qq-dev.appsc.icldig.icl-group.com',
  //PROD
  baseUrl: 'https://api.qq.appsb.icldig.icl-group.com',

  adalConfig: {
    tenant: '802762d2-02c4-4677-98ba-54060a234204',
    clientId: 'fee4f9df-bd76-4668-a951-6cae81aa2b04', 
    redirectUri: 'https://qq.appsb.icldig.icl-group.com/auth/postLogin',
    // redirectUri: 'http://localhost:4200/auth/postLogin',
    resource: 'fee4f9df-bd76-4668-a951-6cae81aa2b04',
  },
  firebase: {
    apiKey: 'AIzaSyBBJdYTsI4y12TGezRY1z6ICv4uzvlL7NA',
    authDomain: 'smartq-b1538.firebaseapp.com',
    projectId: 'smartq-b1538',
    storageBucket: 'smartq-b1538.appspot.com',
    messagingSenderId: '1051885977116',
    appId: '1:1051885977116:web:9fbbe006945775ae0c29d9',
    measurementId: 'G-6W2EWYZJFJ'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
