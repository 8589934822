import { Base } from "./Base";

export class PdfLink extends Base{

    id: number;
    title: string;
    link: string;

    constructor(args : Partial<PdfLink>) { 
        super(args)
    }
}
