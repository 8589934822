<div class="search py-0 py-md-3">
  <mat-form-field class="mx-md-3 mx-0" *ngFor="let i of inputs">
    <mat-label> {{ i.label }} <label class="text-danger">*</label></mat-label>

    <!-- [(value)]="i.label === 'Division' ? divisionDefaultValue : siteDefaultValue" -->
    <mat-select
      *ngIf="i.label !== 'Application'"
      (selectionChange)="handelChange($event, i)"
    >
      <mat-option
        *ngFor="let o of i.options"
        [value]="o.value"
        (click)="optionClick()"
        class="mat-select-prod"
        >{{ o.value }}
      </mat-option>
    </mat-select>

    <!-- [(value)]="appDefaultValue" -->
    <mat-select
      #select
      *ngIf="i.label === 'Application'"
      (selectionChange)="handelChange($event, i)"
      [multiple]="true"
    >
      <div class="mat-select-prod checkBox">
        <mat-checkbox
          [(ngModel)]="allSelected"
          [ngModelOptions]="{ standalone: true }"
          (change)="toggleAllSelection()">
          Select/Clear All
        </mat-checkbox>
      </div>
      <mat-option
        *ngFor="let o of i.options"
        [value]="o.value"
        (click)="optionClick()"
        class="mat-select-prod"
        >{{ o.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
