import { Base } from "./Base";
import { Product } from "./product";
import { Question } from "./question";
import { Site } from "./site";

export class Answer extends Base {
    id: number;
    selected_option: string;
    text: string;
    link: string;
    validity_date: Date;
    question: Question;
    product: Product;
    site: Site;

    constructor(args: Partial<Answer>) {
        super(args)
    }

}

export class AnswerDto extends Base {
    id: number;
    selected_option: string;
    text: string;
    link: string;
    validity_date: Date;
    question: number;
    product: number;
    site: number;

    constructor(args: Partial<AnswerDto>) {
        super(args)
    }

}
