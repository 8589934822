import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(private router: Router) {}
  async canDeactivate(component: ComponentCanDeactivate): Promise<boolean> {
    if (!component.canDeactivate()) {
      console.log('CanDeactivateGuard');
      if (
        confirm(
          'You have unsaved changes! If you leave, your changes will be lost.'
        )
      ) {
        // this.router.navigateByUrl(`/products/1`);
        this.router.navigateByUrl('/products');
        return true;
      } else {
        return false;
      }
    }
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    // const test = this.router.parseUrl(`/products/1`);
    this.router.navigateByUrl('/products');
    return true;
  }
}
