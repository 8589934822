<div class="container-fluide p-0 m-0 px-md-5 px-3 mt-4 col-md-10">
  <global-page-header
    class="
      d-flex
      flex-column flex-md-row
      justify-content-between
      align-items-center
      mb-3 mb-md-5
    "
  >
    <div
      class="
        p-0
        col-12 col-md-6
        font-weight-bold
        text-large
        pb-5 pb-md-0
        arrow-img
      "
      title
    >
      Links to the end of the PDF
    </div>
    <div class="col-12 col-md-6 d-flex justify-content-md-end px-0" btnTitle>
      <button
        (click)="openAddLink()"
        mat-stroked-button
        color="primary"
        class=" btn
        text-normal
        border-primary
        bkg-color-transparent
        color-blue-1
        border-color-blue
        font-weight-bold"
      >
        <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
        Add Links
      </button>
    </div>
  </global-page-header>
  <main-grid [actionTemplate]="editLink" [gridContent]="links" [authClick]="isAuthorized"></main-grid>
</div>

<ng-template #editLink>
  <div class="d-flex justify-around-start align-items-start">
    <!-- <mat-icon>edit</mat-icon> -->
    <img src="../../../../../assets/edit-pen.svg" alt="" />
  </div>
</ng-template>
