<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <global-page-header
    class="
      d-flex
      justify-content-between
      align-items-md-center
      mb-5
      flex-md-row flex-column
    "
  >
    <div
      class="pb-3 pb-md-0 p-0 col-12 col-md-6 font-weight-bold text-large"
      title
    >
      Expiration Dates Report Page
    </div>
    <!-- <div
      class="col-12 col-md-6 d-flex justify-content-md-end p-0 px-md-3"
      btnTitle
    >
      <div
        class="
          sqr
          color-grey-3
          col-12 col-md-7
          d-flex
          justify-content-between
          p-md-2
          py-md-3
        "
      >
        <span class="mx-3 font-weight-bold">Notification every</span>
        <span class="d-flex d-md-block justify-content-between">
          <span class="mx-3">3</span>
          <span class="mx-3 font-weight-bold">Days</span>
        </span>
      </div>
    </div> -->
  </global-page-header>
  <main-grid [actionTemplate]="editLink" [gridContent]="gridContent" [authClick]="isAuthorized"></main-grid>

</div>

<ng-template #editLink>
  <div class="d-flex justify-around-start align-items-center">
    <!-- <button
    (click)="goToProduct()"
    mat-fab
    class="text-normal m-0"
  >
  </button> -->
    <div class="display: flex justify-content: center link" *ngIf="this.isAuthorized"  (click)="goToProduct()">
      link
    </div>
  </div>
</ng-template>
