import { Answer } from './answer';
import { Base } from './Base';
import { Division } from './division';
import { Pdf } from './pdf';
import { Product } from './product';
import { Question } from './question';

export class Site extends Base {
  id: number;
  name: string;
  pdf_text: string;
  signature_name: string;
  created_date: Date;
  updated_date: Date;
  divisions: Division[];
  products: Product[];
  questions: {
    question: Question,
    answer: Answer
  }[];
  answers: Answer[];
  pdfs: Pdf[];

  constructor(args: Partial<Site>) {
    super(args);
  }
}

export class SiteDto extends Base {
  id: number;
  name: string;
  pdf_text: string;
  signature_name: string;
  divisions: Division[];
  products: Product[];
  questions: Question[];
  answers: Answer[];
  pdfs: Pdf;

  constructor(args: Partial<Site>) {
    super(args);
  }
}
