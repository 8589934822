import { Answer } from './answer';
import { Application } from './application';
import { Base } from './Base';
import { Chapter } from './chapter';
import { Site } from './site';
import { Subchapter } from './subchapter';

export class Question extends Base {
  id: number;
  text: string;
  created_date: Date;
  updated_date: Date;
  chapter: Chapter;
  subchapter: Subchapter;
  sites: Site[];
  applications: Application[];
  answers: Answer[];

  constructor(args: Partial<Question>) {
    super(args);
  }
}

export class QuestionDto extends Base {
  text: string;
  chapter: number;
  subchapter: number;
  sites: number[];
  applications: number[];
  answers: number[];
  id: number;

  constructor(args: Partial<QuestionDto>) {
    super(args);
  }
}
