import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { GridContent } from '../../components/grid/grid.component';
import { Site } from 'src/app/globals/base/site';
import { MainService } from '../../main.service';
import { User } from 'src/app/globals/base/user';
import { UsersService } from 'src/app/globals/services/users.service';

@Component({
  selector: 'main-answer-management',
  templateUrl: './answer-management.component.html',
  styleUrls: ['./answer-management.component.scss'],
})
export class MainAnswerManagement implements OnInit {
  siteNames: GridContent;
  sites: Site[] = [];
  allSites;
  user: User;
  isAuthorized:boolean = true;

  constructor(
    private router: Router,
    private location: Location,
    private mainService: MainService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.update();
  }

  searchSites(text) {
    let searchResults = this.allSites.filter((row) =>
      row.cols[0].content.toLowerCase().includes(text.toLowerCase())
    );
    this.siteNames.rows = searchResults;
  }

  async update() {
    this.user = await this.usersService.getCurrentUser();

    try {
      this.mainService.combined$.subscribe((args) => {

        if (this.user.permissions.super_admin) {
            this.sites = args.s;
          } else {
            this.sites = this.user.sites;
          }
        // this.sites = args.s;
        if (this.sites) this.setSiteNames();
      });
    } catch (err) {
      console.error(err);
    }
  }

  private setSiteNames() {
    try {
      this.siteNames = {
        disableAccordion: true,
        header: [
          {
            title: 'Site Name',
            col: 12,
          },
        ],
        rows: [],
      };
      this.sites?.map((s) => {
        this.siteNames.rows.push({
          title: s.name,
          action: this.goToSite.bind(this, s.id),
          cols: [
            {
              content: s.name,
              col: 12,
              weight: 300,
            },
          ],
        });
      });
      this.allSites = [...this.siteNames.rows];
    } catch (err) {
      console.log(err);
    }
  }

  goToSite(id) {
    this.router.navigateByUrl('answer-management/site/' + id);
  }

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }
}
