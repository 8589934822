import { PermissionsService } from './../../../../../globals/services/permissions.service';
import { SuccessDialogModel ,SuccessDialogComponent} from '../../dialog-general/SuccessDialogComponent';
import { ConfirmDialogModel,ConfirmDialogComponent } from '../../dialog-general/ConfirmDialogComponent';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { GridContent } from '../../../components/grid/grid.component';
import { DialogData } from '../../products/product/product.component';
import { ExpiredService } from 'src/app/globals/services/expired.service';
import { AdminNotification } from 'src/app/globals/base/adminNotification';
import { GUARDS } from 'src/app/modules/guard/masterGuards';

@Component({
  selector: 'app-admin-notification',
  templateUrl: './admin-notification.component.html',
  styleUrls: ['./admin-notification.component.scss'],
})
export class AdminNotificationComponent implements OnInit {
  dialogs: DialogData[];
  permanentText: GridContent;
  settings: GridContent;
  settingsText: any;
  currentExpired: string;
  isAuth:boolean = true;

  editExpired: FormGroup;
  addExpired: FormGroup;
  editText: FormGroup;
  addText: FormGroup;
  deleteMode: boolean;
  permission: boolean = false;

  constructor(
    private dialog: MatDialog,
    private expiredService: ExpiredService,
    private permissionsService: PermissionsService

    ) {}

  ngOnInit() {
    this.update();
  }

  async update() {
    await this.getText();
    this.setPermanentText();
    this.setForms();
    this.setDialogs();
    this.isAuthorized();
  }

  async getText() {
    this.settingsText = await this.expiredService.getAllSettings()
  }

  async isAuthorized(){
    this.permission = await this.permissionsService.isAuthorized([GUARDS.SuperAdminGuard]);    
  }

  private setPermanentText() {
    this.permanentText = {
      header: [
        {
          col: 7,
          title: 'Type',
        },
        {
          col: 3,
          title: 'Days',
        },
        {
          col: 2,
          title: '',
        },
      ],
      rows: []
    }

    for (const key in this.settingsText) {
      console.log(`${key}: ${this.settingsText[key]}`);
      if(key === 'DivisionManagerAlertPeriod' || key === 'AnswerInserterAlertPeriod')
      this.permanentText.rows.push({
        title: key,
        action: this.openEditText.bind(this,key),
        cols: [
          {
            key: 'Type',
            content: this.title(key),
            col: 7,
            weight: 300,
          },
          {
            key: 'Days',
            content: `${this.settingsText[key]}`,
            col: 4,
            weight: 300,
          },
          {
            key: '',
            content: 'action',
            col: 1,
            weight: 300,
          },
        ],
      })
    }
  }

  async editSettings (dialog: DialogData) {
    try{
      let message = null;
      if(dialog?.form?.value?.text === null || dialog?.form?.value?.text === undefined )
        message = `This field cannot be saved without numeric value`;
      else if(this.settingsText[this.currentExpired]==dialog?.form?.value?.text)
      message = `This numeric value already exists, please select another value`;
      else if(!this.isNormalInteger(dialog?.form?.value?.text)){
        message = `The days field must contain only a number`;
      }
      if(message){
        const dialogData = new SuccessDialogModel('Error!', message);
        this.dialog.open(SuccessDialogComponent, {
          maxWidth: '400px',
          data: dialogData,
        });
        return
      }
      let newSettings :AdminNotification = {}
      newSettings[this.currentExpired] = dialog?.form?.value?.text;
      await this.expiredService.edit(newSettings)
      this.dialog.closeAll()
      this.update();
    }
    catch(err){
      console.log(err);
    }
  }

  isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
 }

  private setDialogs() {
    this.dialogs = [
      {
        name: 'Add Text',
        form: this.addText,
        mode: 'edit',
        onSubmit: () => {console.log("Add Text")
        },
      },
      {
        name: 'Edit Text',
        form: this.editText,
        mode: 'edit',
        onSubmit: this.editSettings.bind(this),
        extraData:{text:"days"},
      },
      {
        name: 'Add Link',
        form: this.addExpired,
        mode: 'edit',
        onSubmit: () => {console.log("Add Link")},
      },
      {
        name: 'Edit Link',
        form: this.editExpired,
        mode: 'edit',
        onSubmit: this.editSettings.bind(this),
      },
    ];
  }

  private setForms() {
    this.editExpired = new FormGroup({
      title: new FormControl('', [Validators.required]),
      // link: new FormControl('', [Validators.required]),
    });
    this.addExpired = new FormGroup({
      title: new FormControl('', [Validators.required]),
      // link: new FormControl('', [Validators.required]),
    });

    this.editText = new FormGroup({
      title: new FormControl('', [Validators.required]),
      // text: new FormControl('', [Validators.required]),
    });

    this.addText = new FormGroup({
      title: new FormControl('', [Validators.required]),
      // text: new FormControl('', [Validators.required]),
    });
  }

  async openEditText(txt:string) {
    try{
      if(!this.permission) return
      this.currentExpired = txt;
      this.editPdfLinkForm()
      this.dialog.open(DialogEditAdminText, {
        minWidth: '350px',
        data: this.dialogs[1],
      });
    }
    catch(err){
      console.log(err);
    }
  }

  title (key:string) {
    switch (key) {
      case 'DivisionManagerAlertPeriod':
        return 'Admin'
      case 'AnswerInserterAlertPeriod':
      return  'Answer Provider'
      default:
        break;
    }
  }

  private editPdfLinkForm() {
    let title = this.title(this.currentExpired)
    this.dialogs[1].form = new FormGroup({
      title: new FormControl({ value: title, disabled: true }, [
        Validators.required,
      ]),
      text: new FormControl({ value: this.settingsText[this.currentExpired], disabled: false }, [
        Validators.required,
      ]),
    });
  }
}

@Component({
  selector: 'dialog-edit-admin',
  templateUrl: './../dialogs/dialog-edit-text.html',
})
export class DialogEditAdminText {
  constructor(
    public dialogRef: MatDialogRef<DialogEditAdminText>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        this.dialogRef.close(true);
      }
    });
  }
}
