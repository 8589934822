<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <global-page-header
    class="d-flex justify-content-between align-items-center mb-3"
  >
    <div class="col-md-6 font-weight-bold text-large mb-3 pl-5 pl-md-0" title>
      Divisions And Sites
    </div>
  </global-page-header>

  <!-- desktop -->
  <div class="row d-none d-md-flex">
    <ng-container *ngFor="let element of options" class="row">
      <div
        class="mat-card p-0 ml-0 mr-5 my-3"
        (click)="nextStage(element.route)"
      >
        <img src="{{ element.picture }}" alt="" />
        <div class="d-flex flex-column align-items-center">
          <div class="my-3 h4">{{ element.name }}</div>
          <div class="mb-4 row text-muted">
            <div class="mr-1">{{ element.numberOfType }}</div>
            <div>{{ element.name }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- mobile -->
  <div
    class="
      d-flex
      flex-column
      align-items-center
      justify-content-center
      d-md-none
    "
  >
    <ng-container *ngFor="let element of options">
      <div class="mat-card p-0 my-3" (click)="nextStage(element.route)">
        <img src="{{ element.picture }}" alt="" />
        <div class="d-flex flex-column align-items-center">
          <div class="my-3 h4">{{ element.name }}</div>
          <div class="mb-4 row text-muted">
            <div class="mr-1">{{ element.numberOfType }}</div>
            <div>{{ element.name }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
