<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div class="d-md-none"></div>
      <div class="">{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <div class="form-group">
      <h2 class="m-0 text-normal weight-700" for="file">Choose File</h2>
      <input
        type="file"
        id="file"
        class="form-control"
        accept=".xlsx, .xls, .csv"
        (change)="handleFileInput($event.target.files)"
      />
      <div *ngIf="error" class="alert alert-danger">
        Some error occured, check your file.
      </div>
      <div class="alert alert-danger" *ngIf="errorFileType">
        File type is inccorect. Only excel files are allowed.
      </div>
    </div>
    <div class="row justify-content-end mb-3">
      <button
        mat-button
        color="primary"
        class="btn primary text-normal px-5 col-12 col-md-4 mt-5 mt-md-0"
        type="submit"
        (click)="importProduct()"
      >
        Import
      </button>
    </div>
    <!-- <form [formGroup]="data.form" (ngSubmit)="data.onSubmit(data)">
      <div class="row">
        <div class="col-12 my-3 p-0">
          <h2 class="m-0 text-normal weight-700">Product Name</h2>
          <div class="form-group">
            <input
              formControlName="product_name"
              type="text"
              class="form-control"
              placeholder=""
            />
            <div *ngIf="data.form.get('product_name').touched">
              <div *ngIf="data.form.get('product_name').invalid">
                <div
                  *ngIf="data.form.get('product_name').errors?.required && data?.extraData.invalidName"
                  class="alert alert-danger"
                >
                  Product name is required
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="data.form.get('product_name').errors?.pattern"
                >
                  Product name is required in english
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <h2 class="m-0 mb-2 text-normal weight-700">Linked Categories</h2>
          <div class="form-group mb-3">
            <label class="m-0 text-small font-weight-bold" for=""
              >Division</label
            >
            <select
              formControlName="product_division"
              class="form-control text-tiny"
            >
              <option
                *ngFor="let d of data.extraData?.divisions"
                [value]="d.id"
              >
                {{d.name}}
              </option>
            </select>
            <div
              *ngIf="(data?.form.value.product_division==null || data?.form.value.product_division=='') && data?.extraData.invalidDiv"
              class="alert alert-danger"
            >
              Division is required
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="m-0 text-small font-weight-bold" for="">Site</label>
            <select
              formControlName="product_site"
              class="form-control text-tiny"
            >
              <option *ngFor="let s of data.extraData?.sites" [value]="s.id">
                {{s.name}}
              </option>
            </select>
            <div
              *ngIf="(data?.form.value.product_site==null || data?.form.value.product_site=='') && data?.extraData.invalidSite"
              class="alert alert-danger"
            >
              Site is required
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mb-3" *ngIf="showProcutsListToCopy">
        <label class="m-0 text-small font-weight-bold" for="">product</label>
        <select
          formControlName="productIdToCopyAnswer"
          class="form-control text-tiny"
        >
        <option *ngFor="let p of data.extraData?.products" [value]="p.id">
          {{p.name}}
          </option>
        </select>
      </div>
    <div class="d-flex col-12 align-items-center">
      <input    formControlName="needsToCopy" type="checkbox"   (change)="showProcutsListToCopy = !showProcutsListToCopy"  style="margin-right: 5px"/>
      Copy answers from product
    </div>
      <div class="row justify-content-end mb-3">
        <button
          mat-button
          color="primary"
          class="btn primary text-normal px-5 col-12 col-md-4 mt-5 mt-md-0"
        >
          Create product
        </button>
      </div>
    </form> -->
  </div>
</global-dialog-frame>
