import { animate, animateChild, group, query, state, style, transition, trigger } from "@angular/animations";

export const mainRouter =
    trigger('mainRoute', [
        state('in', style({
            opacity: 1,
            transform: "translateX(0%)"
        })),
        state('out', style({
            opacity: 0,
            transform: "translateX(5%)"
        })),
        transition('* => *', [
            style({
                opacity: 0,
                transform: "translateX(5%)"
            }),
            animate('0.6s ease'),
            
        ])
    ])