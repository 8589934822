import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { APPS } from 'src/app/modules/main/main.service';

export interface SearchSelect {
  label: string;
  options: { label: string; value: string }[];
}

export interface LastSearch {
  Site: {
    event: {
      value: string;
    };
  };
  Application: {
    event: {
      value: string[];
    };
  };
  Division: {
    event: {
      value: string;
    };
  };
}

@Component({
  selector: 'global-search-select',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss'],
})
export class SearchSelectComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  @Input() inputs: SearchSelect[] = [];
  @Output() searchChanged = new EventEmitter();
  allSelected = false;
  allCleared = false;
  siteDefaultValue = null;
  appDefaultValue :string[] = null;
  divisionDefaultValue = null;
  isLastSearch= false;

  ngOnInit(): void {    
    
    let lastSearch = localStorage.getItem('lastSearch');
    if (lastSearch) {
      this.isLastSearch = true;
      let lastSearchJson: LastSearch = JSON.parse(lastSearch);
      this.siteDefaultValue = lastSearchJson.Site.event.value;
      this.appDefaultValue = lastSearchJson.Application.event.value;
      this.divisionDefaultValue = lastSearchJson.Division.event.value;

      if(APPS.length === this.appDefaultValue.length){
        this.allSelected = true;
      }      
      this.isLastSearch = false;
    }
    localStorage.removeItem('lastSearch')
  }

  handelChange($event, i) {
    
    if (
      i.label === 'Application' &&
      this.allSelected &&
      $event.value.length !== i.options.length
    ) {
      return;
    }

    if (this.allCleared && $event.value.length !== 0) {
      return;
    }

    this.searchChanged.emit({ event: $event, item: i });
    this.allCleared = false;
  }

  toggleAllSelection() {
    try {
      
      if (this.allSelected) {
        this.select.options.forEach((item: MatOption) => {
          item.select();
        });
      } else {
        this.allCleared = true;
        this.select.options.forEach((item: MatOption) => item.deselect());
      }
    } catch (err) {
      console.error(err);
    }
  }
  optionClick() {
    try {
      
      if (this.select) {
        let newStatus = true;

        this.select.options.forEach((item: MatOption) => {
          if (!item.selected) {
            newStatus = false;
          }
        });
        this.allSelected = newStatus;
      }
    } catch (err) {
      console.error(err);
    }
  }

  clearSearch(){
    
    this.siteDefaultValue = null;
    this.appDefaultValue = null;
    this.divisionDefaultValue =null
  }
}
