import { Injectable } from '@angular/core';
import { User } from '../base/user';
import { IclSmartQApi } from './api.service';
import { AdalService } from 'adal-angular4';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  user:User;
  constructor(
    private iclSmartQApi: IclSmartQApi,
    private adalService: AdalService
  ) {}
  async getCurrentUser(): Promise<User> {
    try {
      let user: User;
      if (this.adalService) {

        if (this.adalService.userInfo.authenticated) {
          let adalUser = await this.adalService.getUser().toPromise();

          if (adalUser && adalUser.userName) {
            let result;
            try{
              user = this.expirePermission();
              if(user) return user;

              result = await this.iclSmartQApi.get(
                '/users/' + adalUser.userName
              );
            }
            catch(err){
              console.log(err);
            }
            // result = null;
            if (result) {              
              user = new User({
                name: adalUser.profile.name,
                email: adalUser.userName,
                division: result.divisions,
                sites: result.sites,
                role: null,
                id: result.id,
                permissions: result.permissions,
                expirePermission:new Date(),
              });
            } else {
              user = new User({
                name: adalUser.profile.name,
                email: adalUser.userName,
                division: [],
                sites: [],
                role: null,
                id: null,
                permissions: {
                  guest: true,
                  // super_admin:true
                  // answer_inserter: true,
                  // division_manager:true,
                  // pdf_creator:true
                },
                expirePermission:new Date()
              });
            }
          }
        }
      }
      this.user = user;

      return user;
    } catch (err) {
      console.error(err);
    }
  }
  async getUsers(params?: any): Promise<User[]> {
    try {
      let users: User[] = [];
      const result = await this.iclSmartQApi.get('/users', { params });
      if (result && result) {
        result.map((user) => users.push(new User(user)));
      }
      return users;
    } catch (err) {
      console.error(err);
    }
  }
  async getUser(id: string): Promise<User> {
    try {
      const result = await this.iclSmartQApi.get('/users/' + id);
      if (result) {
        return new User(result);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async createUser(user: Partial<User>): Promise<User> {
    try {
      const result = await this.iclSmartQApi.post('/users', {
        body: user,
      });
      // if (result && result.user) {
      if (result) {
        return new User(result.user);
      } else {
        throw new Error('something went wrong');
      }
    } catch (err) {
      throw err;
    }
  }
  async createResponsible(fieldId, data) {
    try {
      const result = await this.iclSmartQApi.post('/field/' + fieldId, {
        body: data,
      });
      if (result) {
        return result;
      } else {
        throw new Error('something went wrong');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async editUser(id: number, user: Partial<User>): Promise<boolean> {
    try {
      const result = await this.iclSmartQApi.put('/users/' + id, {
        body: user,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }

  async getUnitByFieldAndUser(params: any): Promise<any> {
    try {
      const result = await this.iclSmartQApi.post('/field/unit', {
        body: params,
      });
      // if (result ) {
      //     result.map(user => users.push(new User(user)));
      // }
      return result;
    } catch (err) {
      console.error(err);
    }
  }
  async search(attrs: string): Promise<User[]> {
    try {
      let users: User[] = [];
      const result = await this.iclSmartQApi.get('/users' + attrs);
      if (result) users = result.map((user) => new User(user));
      return users;
    } catch (err) {
      console.error(err);
    }
  }
  async deleteUser(id: number, params?: any): Promise<boolean> {
    try {
      const result = await this.iclSmartQApi.delete('/users/' + id, {
        params,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  }

  private getExtraData(): {
    givenName: string;
    surname: string;
    mobilePhone: string;
  } {
    return localStorage.getItem('extraData')
      ? JSON.parse(localStorage.getItem('extraData'))
      : {
          givenName: null,
          surname: null,
          mobilePhone: null,
        };
  }

  expirePermission(){
    if(this.user){
      const date = new Date();
      const expirePermission = new Date(this.user.expirePermission.getTime() + 15*60000);
      if(date <=expirePermission){
        return this.user;
      }
    }
    return null
  }
}


