import { Component } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';

@Component({
  template: `<router-outlet></router-outlet>`,
  selector: 'auth',
  styles: [],
})
export class AuthComponent {
  constructor(private adalService: AdalService) {
    this.adalService.init(environment.adalConfig);
    this.adalService.handleWindowCallback();
  }
}
