import { Base } from "./Base";
import { Question } from "./question";
import { Subchapter } from "./subchapter";

export class Chapter extends Base{
 
    id: number;
    title: string;
    position: number;
    created_date: Date;
    updated_date: Date;
    subchapters: Subchapter[];
    questions: Question[];

    constructor(args : Partial<Chapter>) { 
        super(args)
    }

}
