import { Component, Input } from '@angular/core';

@Component({
  selector: 'main-stage-status',
  templateUrl: './stage-status.component.html',
  styleUrls: ['./stage-status.component.scss'],
})
export class MainStageStatus {
	@Input() stage: 1 | 2 | 3 | 4 | 5;
	
}
