import { AdalService } from 'adal-angular4';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthLogin implements OnInit {
  constructor(private adalService: AdalService) {
    this.adalService.init(environment.adalConfig);
    this.adalService.handleWindowCallback();
  }

  ngOnInit() {
    if (this.adalService && !this.adalService.userInfo.authenticated) {
      this.adalService.login();
    } else {
      this.adalService.logOut();
    }
  }
}
