import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User, UserDto } from '../base/user';
import { AdalService } from 'adal-angular4';
import { IclSmartQApi } from './api.service';
import { environment } from 'src/environments/environment';
import { UsersService } from './users.service';
// import { VLADI } from '../mockup/profile';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public user = new BehaviorSubject<User>(undefined);

  constructor(
    private adalService: AdalService,
    private userService: UsersService,
    private iclSmartQApi: IclSmartQApi
  ) {
    this.adalService.init(environment.adalConfig);
  }

  // async update(options: { noLoader?: boolean } = {}): Promise<User> {
  //   try {
  //     let adalUser;
  //     let result;
  //     let iclLabUser;
  //     if (this.adalService) {

  //       if (this.adalService.userInfo.authenticated) {
  //         console.log(this.adalService.userInfo.token);
  //         adalUser = await this.adalService.getUser().toPromise();
  //         if (adalUser && adalUser.userName) {
  //           result = await this.login();
  //           if (result && result.user) {
  //                iclLabUser = new User(result.user);
  //                this.user.next(iclLabUser);
  //                return iclLabUser;
  //              } else {
  //                this.logout();
  //                return;
  //              }

  //           // const iclLabUser = new User(VLADI);
  //           // this.user.next(iclLabUser);
  //           // return iclLabUser;

  //           //  result = await this.login(adalUser.userName);
  //           //  if (result && result.users) {
  //           //    iclLabUser = new User(result.users[0]);
  //           //  } else {
  //           //    this.logout();
  //           //    return;
  //           //  }
  //         }
  //       } else {
  //         this.adalService.login();
  //         return;
  //       }
  //     } else {
  //       this.adalService.login();
  //       return;
  //     }
  //     //  const iclLabUser = new User(VLADI);
  //     //  this.user.next(iclLabUser);
  //     //  return iclLabUser;
  //   } catch (err) {
  //     console.error('[SessionService]', err);
  //   }
  // }

  async update(options: { noLoader?: boolean } = {}): Promise<boolean> {
    try {
      // const iclLabUser = await this.userService.getCurrentUser();
      // console.log('iclLabUser',iclLabUser);

      const isAuthenticated: boolean =
        this.adalService?.userInfo?.authenticated;

       return isAuthenticated;
    } catch (err) {
      console.error('[SessionService]', err);
    }
  }

  private async login(): Promise<User> {
    try {
      // return await this.iclLabsApiService.post('/login', {
      //   body: { email: email },
      // });
      return await this.iclSmartQApi.post('/users/login');
    } catch (err) {
      console.error(err);
    }
  }

  async logout() {
    try {
      this.adalService.logOut();
    } catch (err) {
      console.error('[SessionService]', '(logout)', err);
    }
  }
}
