<div class="container-fluide p-0 m-0 px-md-5 px-0 py-4 vh-100">
  <div class="px-1 d-none d-md-block">
    <div (click)="back($event)">
      <i
        class="
          cursor-pointer
          text-semi-huge
          color-black-1
          bi bi-arrow-left-short
        "
      ></i>
    </div>
  </div>
  <div class="row d-block d-md-flex h-100">
    <div class="py-2 px-md-5 col-12">
      <div class="text-huge font-weight-bold mb-4">Export PDF</div>
      <div class="color-grey-3 text-normal font-weight-bold mb-2 row">
        <div class="mb-3 font-weight-bold h5">Product Name:&nbsp;</div>
        <div class="mb-3 font-weight-bold h5" *ngIf="product">
          {{ product.name }}
        </div>
      </div>
      <div class="color-grey-3 text-normal font-weight-bold mb-2 row">
        <div class="mb-3 font-weight-bold h5">Site Name:&nbsp;</div>
        <div class="mb-3 font-weight-bold h5" *ngIf="product">
          {{ product.site.name }}
        </div>
      </div>
      <div>
        <div class="mb-3 font-weight-bold h5">Customer Details</div>
        <form [formGroup]="stageTwoForm" (ngSubmit)="onSubmit(stageTwoForm)">
          <!-- <div formArrayName="details" *ngFor="let detail of productDetails.get('details')['controls']; let i=index;">
				<div [formGroupName]="i" class="row col-md-6 justify-content-between mb-md-3">
					<label for="{{ detail.value.label }}">{{ detail.value.label }}</label>
					<input formControlName="{{detail.value.label}}" type="text" name="{{ detail.value.label }}" value="{{ detail.value.value }}" class="col-md-8 rounded border"
					/>
				</div>
			</div> -->

          <ng-container *ngFor="let detail of details">
            <div class="row col-md-6 justify-content-between mb-md-3">
              <label for="{{ detail.label }}">{{ detail.label }}</label>
              <input
                name="{{ detail.label }}"
                formControlName="{{ detail.controlName }}"
                type="text"
                class="col-md-6 rounded border"
                *ngIf="!(detail.controlName === 'issuingDate' || detail.controlName === 'applicationEmail')"
              />

              <main-chip-input
                class="col-md-6 rounded border background"
                *ngIf="detail.controlName === 'applicationEmail'"
                (EmailChange)="changeEmail($event)">
              </main-chip-input>


              <mat-form-field *ngIf="detail.controlName == 'issuingDate'">
                <input
                  matInput
                  [matDatepicker]="issuingDate"
                  formControlName="issuingDate"
                  placeholder="Date"
                  name="date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="issuingDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #issuingDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              *ngIf="detail.controlName == 'companyName' && emptyCompanyName"
              class="alert alert-danger col-md-6 justify-content-between p-1"
            >
              Customer Name is required
            </div>
            <div
              *ngIf="detail.controlName == 'companySite' && emptyCompanySite"
              class="alert alert-danger col-md-6 justify-content-between p-1"
            >
              Customer Site is required
            </div>
            <div
              *ngIf="
                detail.controlName == 'contactPerson' && emptyContactPerson
              "
              class="alert alert-danger col-md-6 justify-content-between p-1"
            >
              Customer Contact Person is required
            </div>
            <!-- <div
              *ngIf="
                detail.controlName == 'applicationEmail' &&
                stageTwoForm.get('applicationEmail').invalid &&
                stageTwoForm.get('applicationEmail').touched
              "
              class="alert alert-danger col-md-6 justify-content-between p-1"
            >
              Application Email is invalid, please try again
            </div> -->

              <div
              *ngIf="
                detail.controlName == 'applicationEmail' &&
                !email.valid
              "
              class="alert alert-danger col-md-6 justify-content-between p-1"
            >
              Application Email is invalid, please try again
            </div>

            <!-- <div
              *ngIf="
                detail.controlName == 'applicationEmail' &&
                emptyApplicationEmail
              "
              class="alert alert-danger col-md-6 justify-content-between p-1"
            >
              Application Email is required
            </div> -->
          </ng-container>

          <div class="row justify-content-between col-md-6 px-md-2">
            <button
              mat-button
              class="
                mat-stroked-button
                text-normal
                btn border-primary
                col-6 col-md-3
                m-auto m-md-0
                d-flex d-md-block
              "
              (click)="back($event)"
            >
              Previous
            </button>
            <button
              type="submit"
              mat-button
              class="
                btn
                primary
                text-normal
                btn primary
                col-6 col-md-3
                m-auto m-md-0
                d-flex d-md-block
              "
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
