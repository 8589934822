import { Base } from "./Base";

export class ExpiredReport extends Base {

    id: number;
    text: string;
    link: string;
    selected_option:string;
    validity_date: Date;
    fileName:string;
    site?:{
      id:number;
    };
    question:{
      id:number;
      text:string
    };
    product:{
      id:number;
    };

    constructor(args: Partial<ExpiredReport>) {
        super(args)
    }
}
