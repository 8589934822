<!-- <button
  mat-stroked-button
  color="primary"
  class="text-normal"
  (click)="openDeleteQuestion()"
>
  delete
</button> -->

<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <div class="row justify-content-between">
    <div class="col-12 col-md-6 p-0">
      <div class="d-flex w-100">
        <div class="pt-1 px-1 d-none d-md-block">
          <a (click)="back($event)">
            <i
              class="
                cursor-pointer
                color-black-1
                text-semi-huge
                bi bi-arrow-left-short
              "
            ></i>
          </a>
        </div>
        <div class="w-100">
          <div class="mb-4 d-flex d-md-block justify-content-between">
            <h1>Product Questions Page</h1>
            <div
              class="
                col-6
                align-items-start
                d-flex d-md-none
                px-0 px-md-3
                justify-content-end justify-content-md-center
              "
            >
              <button
                mat-stroked-button
                color="primary"
                class="btn border-primary mx-1 my-3 mt-md-0 px-2"
                (click)="openAddQuestion()"
              >
                <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
                Add Question
              </button>
            </div>
          </div>
          <global-search-input
            (sendSearch)="searchQuestion($event)"
          ></global-search-input>
        </div>
      </div>
    </div>
    <div
      class="
        col-12 col-md-6
        justify-content-end
        align-items-start
        d-none d-md-flex
      "
    >
      <button
      *ngIf="isAuthorized"
        mat-stroked-button
        color="primary"
        class="btn border-primary mx-1 my-3 mt-md-0 px-2"
        (click)="openAddQuestion()"
      >
        <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
        Add Question
      </button>
    </div>
  </div>

  <main-grid [actionTemplate]="edit" [actionTemplatem]="edit" [gridContent]="gridContent" [authClick]="isAuthorized"></main-grid>
</div>

<ng-template #edit>
  <div class="d-flex justify-around-start align-items-start" *ngIf="isAuthorized">
    <img src="../../../../../assets/edit.svg" alt="" />
    Edit
  </div>
</ng-template>
