import {Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';


export class SuccessDialogModel {
  constructor(public title: string, public message: string) {}
}

@Component({
  selector: 'app-success-dialog',
  templateUrl: '../dialog-general/success-dialog.html',
  styleUrls: ['../dialog-general/success-dialog.scss'],
})
export class SuccessDialogComponent implements OnInit {
  title: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SuccessDialogModel
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {}

  async onConfirm(): Promise<void> {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close();
  }
}
