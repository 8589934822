import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChapterService } from 'src/app/globals/services/chapter.service';
import { GUARDS } from 'src/app/modules/guard/masterGuards';
import { SortItem } from '../../components/sort-grid/sort-grid.component';
import { PermissionsService } from './../../../../globals/services/permissions.service';

export interface DialogData {
  name: string;
  form: FormGroup;
  mode: 'edit' | 'view';
  onSubmit?: Function;
  closeDialog?: Function;
  extraData?: any;
}
export interface ChapterPosition {
  chapters: Position[];
}

export interface Position {
  id:       number;
  position: number;
}


@Component({
  selector: 'main-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss'],
})
export class MainChapters implements OnInit {
  chapters: SortItem[];
  dialogs: DialogData[];
  addChapter: FormGroup;
  deleteMode:boolean;
  isAuthorized: boolean = false;


  constructor(
    private router: Router,
    public dialog: MatDialog,
    public chapterService: ChapterService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.update();
  }

  async update() {
    await this.getAllChapters();
    this.setChapters();
    this.setForms();
    this.setDialogs();
    this.setAddAuthorized();
  }

  async setAddAuthorized() {
    try {
      this.isAuthorized = await this.permissionsService.isAuthorized([
        GUARDS.SuperAdminGuard,
      ]);
    } catch (err) {
      console.log(err);
    }
  }

  getStatusPage(){
    return this.router.url;
  }

  deleteChapter(item){
    this.update();
    // 
    // var index = this.chapters.map(x => {
    //   return x.id;
    // }).indexOf(item);

    // this.chapters.splice(index, 1);
  }

  async getAllChapters() {
    try {
       const chapters = await this.chapterService.getAll();
       this.chapters = chapters.sort((a,b)=>{return a.position-b.position});
       console.log(this.chapters);
      } catch (error) {
      console.log(error);
    }
  }

  private setChapters() {
    for (let i = 0; i < this.chapters.length; i++) {
      this.chapters[i]['action'] = this.goToAction.bind(
        this,
        this.chapters[i].id,
        this.chapters[i].title
      );
    }
  }

  async sort(){

    let newChapter: ChapterPosition = {chapters:[]};

    this.chapters.map((item)=>{
      newChapter.chapters.push(
        {
          id:item.id,
          position:item.position
        }
      )
    })
    await this.chapterService.editChapterPosition(newChapter) ;
  }

  private setDialogs() {
    this.dialogs = [
      {
        name: 'Add Chapter',
        form: this.addChapter,
        mode: 'edit',
        onSubmit: this.postChapter.bind(this),
      },
    ];
  }

  private setForms() {
    console.log('setForms');

    this.addChapter = new FormGroup({
      chapter_name: new FormControl('', [Validators.required]),
    });
  }

  openAddChapterDialog() {
    console.log('openAddChapterDialog');

    const dialogRef = this.dialog.open(DialogAddChapter, {
      minWidth: '350px',
      data: this.dialogs[0],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('DialogEditQuestion');
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  async postChapter(dialog: DialogData) {
    try{
      let obj = {
        title: dialog.form?.value?.chapter_name,
        position: this.chapters.length+1
      }
      await this.chapterService.add(obj);
      this.closeDialog();
      this.update()
    }
    catch(err){
      console.log(err);
    }
  }

  goToAction(id, title) {
    this.router.navigateByUrl(`/chapters/${id}/${title}`);
  }
}

@Component({
  selector: 'dialog-add-chapter',
  templateUrl: './dialogs/dialog-add-chapter.html',
})
export class DialogAddChapter {
  constructor(
    public dialogRef: MatDialogRef<DialogAddChapter>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
