import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { GridContent } from '../../../components/grid/grid.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogData } from '../../products/product/product.component';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import { SiteService } from 'src/app/globals/services/site.service';
import { Site, SiteDto } from 'src/app/globals/base/site';
import { Division } from 'src/app/globals/base/division';
import { Product } from 'src/app/globals/base/product';
import { Pdf } from 'src/app/globals/base/pdf';
import { DivisionService } from 'src/app/globals/services/division.service';
import { Pill } from '../../../components/pills/pills.component';
import { Subscription } from 'rxjs';
import { MainService } from '../../../main.service';
import { SuccessDialogComponent, SuccessDialogModel } from '../../dialog-general/SuccessDialogComponent';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../dialog-general/ConfirmDialogComponent';
import { PermissionsService } from 'src/app/globals/services/permissions.service';
import { GUARDS } from 'src/app/modules/guard/masterGuards';


@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
})
export class SitesComponent implements OnInit {
  allDiv: any[];
  subscription: Subscription = new Subscription();
  gridContent: GridContent;
  dialogs: DialogData[];
  editSite: FormGroup;
  addSite: FormGroup;
  searchArray: any[];
  productsArray: any[];
  sites: Site[] = [];
  divisions: Division[];
  currentSite: Site;
  allSite;
  divisionsPillsOptions: Pill[] = [];
  deleteMode: boolean;
  result: string = '';
  divisionName: string = '';
  siteByDivision;
  isAuthorized:boolean = false;

  constructor(
    private location: Location,
    private router: Router,
    public dialog: MatDialog,
    public dialogConf: MatDialog,
    private siteService: SiteService,
    private mainService: MainService,
    private permissionsService: PermissionsService

  ) {
    let site = localStorage.getItem('site');
    if(site){
      let division = JSON.parse(site);
      this.divisionName = division.name
      // 
      this.sites = division.sites;
    }
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async update() {
    try {
      this.siteByDivision = await this.siteService.getAll()
      this.subscription = this.mainService.combined$.subscribe(async (obj) => {
        this.divisions = obj.d;
        this.divisionsPillsOptions = obj.d.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });
        if (this.divisions && this.divisions.length > 0) {
          this.setSite();
          this.setAuthorized();
          this.setForms();
          this.setDialogs();
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  async setAuthorized(){
    this.isAuthorized = await this.permissionsService.isAuthorized([GUARDS.SuperAdminGuard, GUARDS.DivisionManagerGuard]);
  }

  private setDialogs() {
    // 

    this.dialogs = [
      {
        name: 'Add Site',
        form: this.addSite,
        mode: 'edit',
        onSubmit: this.saveSite.bind(this),
        extraData: {
          divisions: [],
          divisionsPillsOptions: this.divisionsPillsOptions,
          invalidName: false,
          invalidDiv: false,
        },
      },
      {
        name: 'Edit Site',
        form: this.editSite,
        mode: 'view',
        onSubmit: this.putSites.bind(this),

        extraData: {
          divisions: [],
          divisionsPillsOptions: this.divisionsPillsOptions,
          changeMode: this.delete.bind(this),
          invalidName: false,
          invalidDiv: false,
        },
      },
    ];
  }

  async delete(status) {
    this.deleteMode = status;
    if (this.deleteMode) {
      const message = `Are you sure you want to delete ${this.currentSite.name} site?`;

      const dialogData = new ConfirmDialogModel('Delete Site', message);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        this.result = dialogResult;
        if (this.result) {
          try {
            await this.siteService.delete(this.currentSite.id);
            this.closeDialog();
            const message = `The site is deleted successfully`;
            const dialogData = new SuccessDialogModel('Success!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
            this.mainService.setSites(await this.siteService.getAll());
            await this.update();
          } catch (err) {
            const message = `You can not delete the site, this site is linked to other elements`;
            const dialogData = new SuccessDialogModel('Error!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
          }
        }
      });
    }
  }

  openAnswerQuestion(): void {
    const dialogRef = this.dialog.open(DialogEditSite, {
      minWidth: '350px',
      data: this.dialogs[1],
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('DialogAnswerQuestion');
    });
  }

  private setForms() {
    this.editSite = new FormGroup({
      signature_name: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      pdf_text: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      link: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      name: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9$@$!%*?&#^-_. +]+$'),
      ]),
    });

    this.addSite = new FormGroup({
      signature_name: new FormControl({ value: null, disabled: false }, [
        Validators.required,
      ]),
      pdf_text: new FormControl({ value: null, disabled: false }, [
        Validators.required,
      ]),
      link: new FormControl({ value: this.divisions, disabled: false }, [
        Validators.required,
      ]),
      name: new FormControl({ value: null, disabled: false }, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9$@$!%*?&#^-_. +]+$'),
      ]),
    });
  }

  openAddSite(): void {
    this.setForms();
    this.dialogs[0].form = this.addSite;
    this.dialogs[0].extraData.divisions = [];
    this.dialogs[0].extraData.invalidName = false;
    this.dialogs[0].extraData.invalidDiv = false;
    const dialogRef = this.dialog.open(DialogEditSite, {
      minWidth: '350px',
      data: this.dialogs[0],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('DialogEditSite');
    });
  }

  openEditSite(s): void {
    // 
    this.dialogs[1].mode = 'view';
    this.currentSite = s;
    this.editSiteForm(s);
    this.setForms();

    const dialogRef = this.dialog.open(DialogEditSite, {
      minWidth: '350px',
      data: this.dialogs[1],
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      console.log(dialogResult);
    });
  }

  searchSite(text) {
    let searchResults = this.allSite.filter((row) =>
      row.cols[0].content.toLowerCase().includes(text.toLowerCase())
    );
    this.gridContent.rows = searchResults;
  }

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }

  private setSite() {
    this.gridContent = {
      header: [
        {
          title: 'Site Name',
          col: 7,
        },
        // {
        //   title: 'Division',
        //   col: 4,
        // },
        // {
        //   title:'',
        //   col:1
        // }
      ],
      rows: [],
    };

    this.sites.map((s) => {
      this.gridContent.rows.push({

        action: this.openEditSite.bind(this, s),
        cols: [
          {
            content: s.name,
            col: 7,
            weight: 400,
          },
          // {
          //   content: s.divisions.length + ' divisions',
          //   col: 4,
          //   weight: 400,
          // },
          // {
          //   content:'action',
          //   col:1
          // }
        ],
      });
    });
    this.allSite = [...this.gridContent.rows];
  }

  async saveSite(dialog: DialogData) {
    if (dialog.mode === 'view') {
    } else {
      if (
        !dialog.form.get('name').invalid &&
        dialog.extraData.divisions.length > 0
      ) {
        await this.addNewSite(dialog);
      }
      if (dialog.form.get('name').invalid) {
        this.dialogs[0].extraData.invalidName = true;
      }
      if (dialog.extraData.divisions.length == 0) {
        this.dialogs[0].extraData.invalidDiv = true;
      }
    }
  }

  private async addNewSite(dialog: DialogData) {
    try {
      const site: Partial<SiteDto> = {
        name: dialog.form.value.name,
        pdf_text: dialog.form.value.pdf_text,
        // products: Product[1],
        divisions: dialog.extraData?.divisions
          ? dialog.extraData?.divisions
              .filter((a) => a.value)
              ?.map((a) => a.value)
          : [],
        signature_name: dialog.form.value.signature_name,
      };
      await this.siteService.add(site);
      this.mainService.setSites(await this.siteService.getAll());

      this.closeDialog();
      await this.update();

      const message = `The site was created successfully`;
      const dialogData = new SuccessDialogModel('Success!', message);
      this.dialog.open(SuccessDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });
    } catch (err) {
      this.closeDialog();
      await this.update();

      const message = `An error occurred while creating the site`;
      const dialogData = new SuccessDialogModel('Error!', message);
      this.dialog.open(SuccessDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });
    }
  }

  async putSites(dialog: DialogData) {
    // 

    if (!this.deleteMode) {
      if (dialog.mode === 'edit') {
        if (
          !dialog.form.get('name').invalid &&
          dialog.extraData.divisions.length > 0
        ) {
          dialog.mode = 'view';
          let index= -1;
          let s = this.sites.find((s) => {
            index++;
            return s.id === this.currentSite.id
          });

          try {
            const siteDto: SiteDto = new SiteDto({
              id: s.id,
              name: dialog.form.value.name,
              pdf_text: dialog.form.value.pdf_text,
              // products: s.products?.map((prod) => prod),
              divisions: dialog.extraData.divisions?.map((d) => d.value),
              signature_name: dialog.form.value.signature_name,
            });
            this.sites[index].pdf_text = siteDto.pdf_text
            this.sites[index].name = siteDto.name
            this.sites[index].signature_name = siteDto.signature_name
            await this.siteService.edit(this.currentSite.id, siteDto);
            this.closeDialog();
            this.update();
          } catch (error) {
            throw error;
          }
        }
        if (
          dialog.form.get('name').invalid ||
          dialog.extraData.divisions.length == 0
        ) {
          if (dialog.form.get('name').invalid) {
            this.dialogs[1].extraData.invalidName = true;
          }
          if (dialog.extraData.divisions.length == 0) {
            this.dialogs[1].extraData.invalidDiv = true;
          }
          return;
        }
      } else {
        dialog.mode = 'edit';
        dialog.form.enable();
      }
    }
  }

  private editSiteForm(s: SiteDto) {

    this.dialogs[1].form = new FormGroup({
      signature_name: new FormControl(
        { value: s.signature_name, disabled: true },
        [Validators.required]
      ),
      pdf_text: new FormControl({ value: s.pdf_text, disabled: true }, [
        Validators.required,
      ]),
      // link: new FormControl({ value: 'fr', disabled: true }, [
      //   Validators.required,
      // ]),
      name: new FormControl({ value: s.name, disabled: true }, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9$@$!%*?&#^-_. +]+$'),
      ]),
    });

    // 
      var index = this.siteByDivision.map(x => {
      return x.id;
    }).indexOf(s.id);

    if(index>-1){
      let obj = [];
      this.dialogs[1].extraData.divisions = this.siteByDivision[index].divisions.map((d) => {
      return {
        label: d.name,
        value: d.id,
      };
    });

    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  nextStage() {
    this.router.navigateByUrl('/products/45/stage-five');
  }
}

@Component({
  selector: 'dialog-edit-site',
  templateUrl: '../dialogs/dialog-edit-site.html',
})
export class DialogEditSite {
  result: string = '';
  isEdit = false;

  constructor(
    public dialogRef: MatDialogRef<DialogEditSite>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.disableClose = true;

    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onNoClick());
  }

  onEdit(): void {
    this.isEdit = true
  }

  onNoClick(): void {
    if(!this.isEdit){
      this.dialogRef.close(true);
      return;
    }
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      this.result = dialogResult;
      if (this.result) {
        this.dialogRef.close(true);
      }
    });
  }
}
