import { Base } from "./Base";

export class PdfText extends Base {

    id: number;
    title: string;
    text: string;

    constructor(args: Partial<PdfText>) {
        super(args)
    }
}
