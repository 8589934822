import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Site } from 'src/app/globals/base/site';
import { DivisionService } from 'src/app/globals/services/division.service';
import { SiteService } from 'src/app/globals/services/site.service';

@Component({
  selector: 'main-divisions-and-sites',
  templateUrl: './divisions-and-sites.component.html',
  styleUrls: ['./divisions-and-sites.component.scss'],
})
export class MainDivisionsAndSites {
  constructor(private router: Router,private siteService: SiteService,private divisionService: DivisionService) {}

  options = [
    {
      name: 'Divisions',
      numberOfType: null,
      picture: '../../../../../assets/division.png',
      route: 'divisions',
    },
    {
      name: 'Sites',
      numberOfType: null,
      picture: '../../../../../assets/sites.png',
      route: 'sites',
    },
  ];
  sites: Site[] = [];


  ngOnInit(): void {
    this.update();
  }

  async update() {
    // await this.getAllDivisions();
    // await this.getAllSites();
  }

  async getAllDivisions() {
    try {
      this.options[0].numberOfType = await (await this.divisionService.getAll()).length;
    } catch (error) {
      console.log(error);
    }
  }

  async getAllSites() {
    try {
      this.options[1].numberOfType = await (await this.siteService.getAll()).length;
    } catch (err) {
      throw new Error(err);
    }
  }

  nextStage(page) {
    this.router.navigateByUrl(page);
  }
}
