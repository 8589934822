import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridContent } from '../../components/grid/grid.component';

@Component({
  selector: 'main-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class MainSettings {
  isAuthorized:boolean = true;


  constructor(private router: Router) {}

  options :GridContent= {
    header: [
      {
        title: '',
        col: 12,
      }
    ],
    rows: [
      {
        action:this.nextStage.bind(this, 'permanent-text'),
        cols: [
          {
            key: '',
            content: 'Permanent texts',
            col: 12,
            weight: 300,
          },
        ]
      },
      {
        action:this.nextStage.bind(this, 'links-to-pdf'),
        cols: [
          {
            key: '',
            content: 'Links To The End Of The PDF',
            col: 12,
            weight: 300,
          },
        ]
      },
      {
        action:this.nextStage.bind(this, 'admin-notification'),
        cols: [
          {
            key: '',
            content: 'Admin Notification',
            col: 12,
            weight: 300,
          },
        ]
      }
    ],
  }
/*
  options= [
    {

      name: 'Permanent texts',
      picture: '../../../../../../assets/permanent-text-mobile.svg',
      route: 'permanent-text',
    },
    {
      name: 'Links To The End Of The PDF',
      picture: '../../../../../../assets/link-to-pdf-mobile.svg',
      route: 'links-to-pdf',
    },
    {
      name: 'Admin Notification',
      picture: '../../../../../../assets/link-to-pdf-mobile.svg',
      route: 'admin-notification',
    },
  ];

  */

  nextStage(page) {
    this.router.navigateByUrl(page);
  }
}
