import { SessionService } from './../../../../globals/services/session.service';
import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/globals/services/users.service';
import { GUARDS } from 'src/app/modules/guard/masterGuards';
import { GuestGuard } from 'src/app/modules/guard/guestGuard';

export interface MenuItem {
  label: string;
  value: string;
  canLoad: boolean;
}

export const ROUTES = {
  PRODUCTS: {
    root:'/products',
    permission:[GUARDS.Guest,GUARDS.SuperAdminGuard,GUARDS.AnswerInserterGuard,GUARDS.DivisionManagerGuard,GUARDS.PdfCreatorGuard]
  },
  ADD_PRODUCTS: {
    root:'/addProduct',
    permission:[GUARDS.SuperAdminGuard,GUARDS.AnswerInserterGuard,GUARDS.DivisionManagerGuard]
  },
  QUESTIONS:{
    root:'/questions',
    permission:[GUARDS.SuperAdminGuard,GUARDS.AnswerInserterGuard,GUARDS.DivisionManagerGuard]
  } ,
  ANSWER_MANAGEMENT: {
    root:'/answer-management',
    permission:[GUARDS.SuperAdminGuard, GUARDS.AnswerInserterGuard, GUARDS.DivisionManagerGuard]
  },
  CHAPTERS: {
    root:'/chapters',
    permission:[GUARDS.SuperAdminGuard]
  },
  DIVISIONS_AND_SITE: {
    root:'/divisions',
    permission:[GUARDS.SuperAdminGuard]
  },
  USER_MANAGEMENT: {
    root:'/user-management',
    permission:[GUARDS.SuperAdminGuard]
  },
  REPORTS: {
    root:'/reports',
    permission:[GUARDS.SuperAdminGuard,GUARDS.DivisionManagerGuard]
  },
  MY_REPORTS: {
    root:'/my-reports',
    permission:[GUARDS.SuperAdminGuard,GUARDS.AnswerInserterGuard,GUARDS.DivisionManagerGuard,GUARDS.PdfCreatorGuard]
  },
  SETTINGS: {
    root:'/settings',
    permission:[GUARDS.SuperAdminGuard]
  },
};

@Component({
  selector: 'main-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class MainSideBar implements OnInit {
  routes: MenuItem[];
  permission: String = '';
  userName: String =  '';
  constructor(
    private sessionService: SessionService,
    private userService: UsersService
  ) {}

  ngOnInit() {
    this.update();
  }

  async update() {
    await this.setPermission();
    await this.setRoute();
  }

  async setRoute() {    
    this.routes = [
      // {
      //   label: 'Products',
      //   value: ROUTES.PRODUCTS.root,
      //   canLoad: await this.activateGuard(ROUTES.PRODUCTS.permission),
      // },
      {
        label: this.permission !== 'GuestGuard' ? 'New Questionnaire' : 'No Access',
        value: ROUTES.PRODUCTS.root,
        canLoad: await this.activateGuard(ROUTES.PRODUCTS.permission),
      },
      {
        label: 'Product Management',
        value: ROUTES.ADD_PRODUCTS.root,
        canLoad: await this.activateGuard(ROUTES.ADD_PRODUCTS.permission),
      },
      {
        label: 'Product Questions',
        value: ROUTES.QUESTIONS.root,
        canLoad: await this.activateGuard(ROUTES.QUESTIONS.permission),
      },
      {
        label: 'Site Questions',
        value: ROUTES.ANSWER_MANAGEMENT.root,
        canLoad: await this.activateGuard(ROUTES.ANSWER_MANAGEMENT.permission),
      },
      {
        label: 'Chapters',
        value: ROUTES.CHAPTERS.root,
        canLoad: await this.activateGuard(ROUTES.CHAPTERS.permission),
      },
      {
        label: 'Divisions and sites',
        value: ROUTES.DIVISIONS_AND_SITE.root,
        canLoad: await this.activateGuard(ROUTES.DIVISIONS_AND_SITE.permission),
      },
      {
        label: 'User Management',
        value: ROUTES.USER_MANAGEMENT.root,
        canLoad: await this.activateGuard(ROUTES.USER_MANAGEMENT.permission),
      },
      {
        label: 'Expiration',
        value: ROUTES.REPORTS.root,
        canLoad: await this.activateGuard(ROUTES.REPORTS.permission),
      },
      {
        label: 'My Reports',
        value: ROUTES.MY_REPORTS.root,
        canLoad: await this.activateGuard(ROUTES.MY_REPORTS.permission),
      },
      {
        label: 'Settings',
        value: ROUTES.SETTINGS.root,
        canLoad: await this.activateGuard(ROUTES.SETTINGS.permission),
      },
    ];
  }

  logout(e) {
    e.preventDefault();
    this.sessionService.logout();
  }

  async activateGuard(permissionArray) {
    if(permissionArray.includes(this.permission )) return true;
    return false;
  }

  async setPermission(){
    this.permission = GUARDS.Guest
    const currentUser = await this.userService.getCurrentUser();
    this.userName = currentUser.name;

    if(currentUser.permissions.super_admin){
      this.permission = GUARDS.SuperAdminGuard;
    }
    else if(currentUser.permissions.answer_inserter){
      this.permission = GUARDS.AnswerInserterGuard;
    }
    else if(currentUser.permissions.division_manager){
      this.permission = GUARDS.DivisionManagerGuard;
    }
    else if(currentUser.permissions.pdf_creator){
      this.permission = GUARDS.PdfCreatorGuard;
    }
  }

  removeLocalStorage(){
    localStorage.removeItem('lastSearch');
  }
}
