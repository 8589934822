import { Application } from "./application";
import { Base } from "./Base";
import { Division } from "./division";
import { Product } from "./product";
import { Site } from "./site";
import { User } from "./user";

export class Pdf extends Base {
  
    id: number;
    customer_name: string;
    customer_site: string;
    contact_person_distributor: string;
    distributor_name: string;
    applicant_email_address: string;
    product_name: string;
    issuing_date: Date;
    company_name: string;   
    link: string; 
    created_date: Date;
    product: Product;
    applications: Application[];
    division: Division;   
    site: Site;
    user: User;

    constructor(args: Partial<Pdf>) {
        super(args)
    }
}
