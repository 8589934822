<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <div class="row justify-content-between">
    <div class="col-12 col-md-6 p-0">
      <div class="d-flex w-100">
        <div class="w-100">
          <div class="mb-4 d-flex d-md-block justify-content-between">
            <h1>User Management</h1>
          </div>
          <div class="row mb-5">
            <div class="col-12 col-md-6">
              <global-search-input
                (sendSearch)="searchUsers($event)"
              ></global-search-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        col-12 col-md-6
        justify-content-end
        align-items-start
        d-none d-md-flex
      "
    >
      <button
        (click)="openAddUser()"
        mat-stroked-button
        color="primary"
        class="
          btn
          text-normal
          border-primary
          bkg-color-transparent
          color-blue-1
          border-color-blue
          font-weight-bold
        "
      >
        <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
        Add User
      </button>
    </div>
  </div>

  <main-grid
    [actionTemplate]="edit"
    [gridContent]="gridContent"
    [authClick]="isAuthorized"
  ></main-grid>
</div>

<ng-template #edit>
  <div class="display: flex justify-content: center">
    <img src="../../../../../assets/edit.svg" alt="" />
    Edit
  </div>
</ng-template>
