import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';
import { User, UserDto } from '../base/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: IclSmartQApi) {}

  async get(id: number): Promise<User> {
    try {
      const res = await this.apiService.get('/users/' + id);
      const _user: User = new User(res);
      return _user;
    } catch (err) {
      throw err;
    }
  }

  async getAll(): Promise<Array<User>> {
    try {
      const records = await this.apiService.get('/users');
      const _user: User[] = records?.map((s) => new User(s));
      return _user;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async add(user: Partial<UserDto>): Promise<User> {
    try {
      const res = await this.apiService.post('/users', { body: user });
      const _user: User = new User(res);
      return _user;
    } catch (err) {
      throw err;
    }
  }

  async edit(id: number, user: Partial<User>): Promise<User> {
    try {
      const res = await this.apiService.put('/users/' + id, {
        body: { ...user },
      });
      const _user: User = new User(res);
      return _user;
    } catch (err) {
      throw err;
    }
  }

  async delete(id: number): Promise<User> {
    try {
      const res = await this.apiService.delete('/users/' + id, {});
      const _user: User = new User(res);
      return _user;
    } catch (err) {
      throw err;
    }
  }
}
