<div class="container-fluide p-0 m-0 px-md-5 px-3 py-5">
  <global-page-header
    class="
      d-flex
      justify-content-between
      align-items-md-center
      mb-5
      flex-md-row flex-column
    "
  >
    <div
      class="pb-3 pb-md-0 p-0 col-12 col-md-6 font-weight-bold text-large"
      title
    >
     My Reports Page
    </div>
  </global-page-header>



  <div class="px-3 text-medium pb-3 pb-md-0">Search by</div>
  <div
    class="
      row
      justify-content-md-start justify-content-center
      align-items-center
      d-block d-md-flex
    "
  >
  <mat-form-field class="input-field ml-md-3 mx-0">
    <input type="text" placeholder="User" aria-label="Number" matInput [formControl]="userControl" [matAutocomplete]="auto2">
    <mat-autocomplete #auto2="matAutocomplete">
      <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
        {{user}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="input-field mx-md-3 mx-0">
    <input type="text" placeholder="Customer" aria-label="Number" matInput [formControl]="custControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let cust of filteredCustomers | async" [value]="cust">
        {{cust}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
    <global-search-select
      [inputs]="searchArray"
      (searchChanged)="searchChanged($event)"
    ></global-search-select>

  </div>
  <div
  class="
    row
    pb-3
    justify-content-md-start justify-content-center
    align-items-center
    d-block d-md-flex
  ">
    <button
    (click)="clearSearchOptions()"
    color="primary"
    class="text-normal mr-3 mx-md-3 btn border-primary">
    <span class="px-3 font-weight-bold"> Clear searches </span>
    </button>
  </div>
  <main-grid [actionTemplate]="editLink" [gridContent]="gridContent" [authClick]="isAuthorized"></main-grid>

</div>


<ng-template #editLink>
  <div class="d-flex justify-around-start align-items-center">
    <div class="display: flex justify-content: center link"  (click)="goToProduct()">
      Download
    </div>
  </div>
</ng-template>
<!--
<div class="container-fluide p-0 m-0 px-md-5 px-3 py-3">
  <global-page-header
    class="d-flex justify-content-between align-items-center mb-3"
  >
    <div class="col-6 font-weight-bold text-large" title>Reports Page</div>
    <div class="col-6 d-flex justify-content-end px-1" btnTitle>

    </div>
  </global-page-header>
  <div class="px-3 text-medium pb-3 pb-md-0">Search by</div>
  <div
    class="
      row
      justify-content-md-start justify-content-center
      align-items-center
      pb-3
      d-block d-md-flex
    "
  >
    <global-search-select
      [inputs]="searchArray"
      (searchChanged)="searchChanged($event)"
    ></global-search-select>
    <button
      (click)="clearSearchOptions()"
      color="primary"
      class="text-normal mr-3 mx-md-3 btn border-primary">
      <span class="px-5 font-weight-bold"> clear searches </span>
    </button>
  </div>
  <div class="row product-card">
    <div
      class="col-12 col-md-6 col-lg-3 mb-3"
      *ngFor="
        let prod of productsArray
          | paginate: { itemsPerPage: 20, currentPage: p }
      "
    >
      <a>
        <global-card-frame>
          <div class="my-2" title>{{ prod.label }}</div>
          <div division>{{ prod.division }}</div>
          <div class="m-auto" site>{{ prod.site }}</div>
          <div row style="display: flex">
            <button
              [routerLink]="'/products/' + prod.id"
              mat-stroked-button
              color="primary"
              class="btn border-primary mx-1 my-3 mt-md-0 px-2"
              style="font-size: 45%"
              product
            >
              <span class="font-weight-bold">edit</span>
            </button>
            <button
              *ngIf="!searchBySite || !searchByDivision || !searchByApplication"
              class="btn primary mx-1 my-3 mt-md-0 px-1 disabled"
              style="font-size: 45%"
              product
            >
              <img src="../../../../../../assets/export.svg" alt="" />
              <span class="ml-1">Export</span>
            </button>
            <button
              *ngIf="searchBySite && searchByDivision && searchByApplication"
              (click)="export(prod)"
              mat-button
              class="btn primary mx-1 my-3 mt-md-0 px-1 primary"
              style="font-size: 45%"
              product
            >
              <img src="../../../../../../assets/export.svg" alt="" />
              <span class="ml-1">Export</span>
            </button>
          </div>
        </global-card-frame>
      </a>
    </div>
  </div>
  <div class="no-result-msg">{{ msg }}</div>
  <div class="row my-5 justify-content-center">
    <pagination-controls
      (pageChange)="p = $event"
      [previousLabel]="null"
      [nextLabel]="null"
    ></pagination-controls>
  </div>
</div> -->
