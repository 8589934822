import { QuestionDto } from './../base/question';
import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';
import { Question } from '../base/question';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  constructor(private apiService: IclSmartQApi) {}

  async get(id: number): Promise<Question> {
    try {
      const res = await this.apiService.get('/questions/' + id);
      const _question: Question = new Question(res);
      return _question;
    } catch (err) {
      throw err;
    }
  }

  async getAll(): Promise<Array<Question>> {
    try {
      const records = await this.apiService.get('/questions');
      const _questions: Question[] = records?.map((q) => new Question(q));
      return _questions;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async add(question: Partial<QuestionDto>) {
    try {
      const res = await this.apiService.post('/questions', { body: question });
      const _question: Question = new Question(res);
      return _question;
    } catch (err) {
      throw err;
    }
  }

  async edit(id: number, question: Partial<QuestionDto>): Promise<Question> {
    try {
      const res = await this.apiService.put('/questions/' + id, {
        body: question,
      });
      const _question: Question = new Question(res);
      return _question;
    } catch (err) {
      throw err;
    }
  }

  async delete(id: number): Promise<Question> {
    try {
      const res = await this.apiService.delete('/questions/' + id, {});
      const _question: Question = new Question(res);
      return _question;
    } catch (err) {
      throw err;
    }
  }
}
