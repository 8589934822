<mat-drawer-container class="example-container">
  <mat-drawer mode="side" [opened]="opened">
    <div
      class="d-flex justify-content-between justify-content-md-center py-3 px-4"
    >
      <div *ngIf="opened && isMobile" class="" (click)="toggleSideBar()">
        <img src="../../../assets/close-sidebar-mobil.svg" alt="" />
      </div>
      <div class="">
        <img src="../../../assets/SmartQ.svg" alt="" />
      </div>
      <div class=""></div>
    </div>
    <main-sidebar></main-sidebar>
  </mat-drawer>
  <mat-drawer-content style="overflow-x: hidden">
    <div
      class="
        d-flex d-md-none
        justify-content-between justify-content-md-center
        py-3
        px-4
        bg-header
      "
    >
      <div *ngIf="!opened && isMobile" class="" (click)="toggleSideBar()">
        <img src="../../../assets/open-sidebar-mobil.svg" alt="" />
      </div>
      <div class="">
        <img src="../../../assets/SmartQ.svg" alt="" />
      </div>
      <div class=""></div>
    </div>
    <ng-container *ngIf="showRouterOutler()">
      <div [@mainRoute]="outlet.isActivated ? outlet.activatedRoute : ''">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </ng-container>
  </mat-drawer-content>
</mat-drawer-container>
