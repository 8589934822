<form
  class="bg-light d-flex flex-column p-3 fixed-bottom"
  style="border-radius: 8px 8px 0 0"
  (ngSubmit)="data.onSubmit(data)"
  [formGroup]="data.form"
>
  <i
    class="bi bi-x-lg cursor-pointer d-flex justify-content-end mb-2"
    (click)="onNoClick()"
  ></i>
  <img
    src="../../../../../../assets/garbage.svg"
    alt=""
    style="width: fit-content"
    class="mb-3"
  />
  <div class="text-normal h4 font-weight-bold mb-2">
    Are you sure delete this file?
  </div>
  <div class="primary mb-3">if you delete the file you can't recover it</div>
  <div class="d-flex flex-column">
    <ng-container *ngIf="data?.mode === 'view'">
      <button mat-stroked-button class="primary btn mb-2" type="submit">
        Continue
      </button>
      <button
        mat-stroked-button
        color="primary"
        class="btn border-primary mb-2"
        type="submit"
      >
        Show me
      </button>
    </ng-container>

    <ng-container *ngIf="data?.mode !== 'view'">
      <button mat-stroked-button class="primary btn mb-2" type="submit">
        Delete
      </button>
      <button
        mat-stroked-button
        color="primary"
        class="btn border-primary mb-2"
        type="submit"
        (click)="onNoClick()"
      >
        Cancel
      </button>
    </ng-container>
  </div>
</form>
