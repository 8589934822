import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class LoaderService {
    private showLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showLoader$ = this.showLoader.asObservable()

    setShowLoader(showLoader: boolean) {
        
        this.showLoader.next(showLoader);
    }

}