import { PdfService } from './../../../../globals/services/pdf.service';
import { MyReports } from './../../../../globals/base/myReports';
import { ExpiredReport } from '../../../../globals/base/ExpiredReport';
import { Component, OnInit } from '@angular/core';
import { GridContent } from '../../components/grid/grid.component';
import { Router } from '@angular/router';
import { SearchSelect } from 'src/app/globals/components/search-select/search.component';
import { APPS, MainService } from '../../main.service';
import { Site } from 'src/app/globals/base/site';
import { Observable, Subscription } from 'rxjs';
import { Pdf } from 'src/app/globals/base/pdf';
import { ProductService } from 'src/app/globals/services/product.service';
import { Division } from 'src/app/globals/base/division';
import { FormControl } from '@angular/forms';
import { map, startWith, switchMap } from 'rxjs/operators';
import { IclSmartQApi } from 'src/app/globals/services/api.service';
import { UsersService } from 'src/app/globals/services/users.service';
import { PermissionsService } from 'src/app/globals/services/permissions.service';
import { GUARDS } from 'src/app/modules/guard/masterGuards';

interface Filter {
  site: boolean;
  division: boolean;
  application: [];
}

export interface AppEntity {
  id: number;
  name: string;
}

@Component({
  selector: 'main-my-reports',
  templateUrl: './my-reports.component.html',
  styleUrls: ['./my-reports.component.scss'],
})
export class MainMyReports implements OnInit {
  gridContent: GridContent;
  gridReports: GridContent;
  reports: MyReports[] = [];
  getQuestion: Boolean;
  currentReports: ExpiredReport;
  searchArray: SearchSelect[];
  applicationFiler = [];
  siteFilter = [];
  divisionFilter = [];
  filter: Filter = { site: false, division: false, application: [] };
  sites: Site[] = [];
  division: Division[] = [];
  questionArray: any[] = [];
  subscription = new Subscription();
  custControl = new FormControl();
  customers: string[] = [];
  filteredCustomers: Observable<string[]>;
  userControl = new FormControl();
  users: string[] = [];
  filteredUsers: Observable<string[]>;
  isAuthorized: boolean = true;

  constructor(
    private mainService: MainService,
    private pdfService: PdfService,
    private productService: ProductService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.update();
  }

  async update() {
    try {
      this.subscription = this.mainService.combined$.subscribe(async (obj) => {
        //understand the code you write!
        this.sites = obj.s;
        this.division = obj.d;
        if (this.sites.length > 0 && this.division.length > 0) {
          await this.getReports();
          this.setReports(this.reports);
          this.setSearch();
        }
      });

      this.filteredCustomers = this.custControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterCustomer(value))
      );

      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterUser(value))
      );
    } catch (err) {
      console.error(err);
    }
  }

  async getReports() {
    try {
      const pdfExport: Pdf = await this.pdfService.getAll();
      this.reports = [];

      for (const key in pdfExport) {
        const applications: string[] = [];
        const sites = [pdfExport[key].site.name];
        const division = [pdfExport[key].division.name];
        const custName = pdfExport[key].customer_name;
        const divName = pdfExport[key].division.name;
        const userName = pdfExport[key].user
          ? pdfExport[key].user.name.toLowerCase()
          : '';
        this.customers.indexOf(custName) === -1
          ? this.customers.push(custName)
          : '';
        pdfExport[key].user
          ? this.users.indexOf(userName) === -1
            ? this.users.push(userName)
            : ''
          : '';

        for (
          let index = 0;
          index < pdfExport[key].applications.length;
          index++
        ) {
          const element: AppEntity = pdfExport[key].applications[index];
          applications.push(element.name);
        }

        this.reports.push({
          id: pdfExport[key].id,
          site: sites,
          applications: applications,
          customerName: custName,
          divisionName: divName,
          Distributer: pdfExport[key].distributor_name,
          date: pdfExport[key].created_date,
          fileName: pdfExport[key].file_name,
          division: division,
          user: pdfExport[key].user,
        });
      }

      this.reports.sort((a, b) =>
        a.date > b.date ? -1 : b.date > a.date ? 1 : 0
      );

    } catch (err) {
      console.error(err);
    }
  }

  goToProduct() {
    this.getQuestion = true;
  }

  async chooseReports(reports: ExpiredReport) {
    try {
      this.currentReports = reports;
      if (this.getQuestion) {
        await this.productService.exportExistPDF(reports.fileName, { reports }); //this method implementation implies the need for a try/catch here
      }
      this.getQuestion = false;
    } catch (err) {
      console.error(err);
    }
  }

  private async setReports(reports: MyReports[]) {
    const userRes = await this.usersService.getCurrentUser();

    this.gridContent = {
      header: [
        {
          col: 2,
          title: 'Date',
        },
        {
          col: 2,
          title: 'Customer',
        },
        {
          col: 1,
          title: 'Site',
        },
        {
          col: 3,
          title: 'Applications',
        },
        {
          col: 2,
          title: 'Distributer',
        },
        {
          col: 1,
          title: 'Division',
        },
        {
          col: 1,
          title: '',
        },
      ],
      rows: [],
    };
    reports?.map((report) => {
      let date = new Date(report?.date);
      let month = date?.toLocaleString('default', { month: 'short' });
      let newDate = month + ' ' + date?.getDate() + ', ' + date?.getFullYear();
      //This is a fine implementation but an unusual date format for display - is this correct?  "7.6.21"
      // let newDate = `${date?.getDate()}.${date?.getMonth() + 1}.${
      //   date?.getFullYear() % 100
      // }`;

      let filterSite = userRes.sites.map((site) => site.name);

      if (
        userRes.permissions.super_admin ||
        filterSite.includes(report.site[0])
      ) {
        this.gridContent.rows.push({
          action: this.chooseReports.bind(this, report),
          cols: [
            {
              key: 'Date',
              content: newDate,
              col: 2,
              weight: 400,
            },
            {
              key: 'Customer',
              content: report?.customerName,
              col: 2,
              weight: 400,
            },
            {
              key: 'Site',
              content: report?.site,
              col: 1,
              weight: 400,
            },
            {
              key: 'Applications',
              content: report?.applications,
              col: 3,
              weight: 400,
            },
            {
              key: 'Distributer',
              content: report?.Distributer,
              col: 2,
              weight: 400,
            },
            {
              key: 'Division',
              content: report?.divisionName,
              col: 1,
              weight: 300,
            },
            {
              key: '',
              content: 'action',
              col: 1,
              weight: 300,
            },
          ],
        });
      }
    });
  }

  clearSearchOptions() {
    this.setSearch();
    this.applicationFiler = [];
    this.filter = { site: false, division: false, application: [] };
    this.custControl.setValue('');
    this.userControl.setValue('');
    this.questionArray = [];
    this.setReports(this.reports);
  }

  private setSearch() {
    this.searchArray = [
      {
        label: 'Site', //constants,
        options: this.sites.map((s) => {
          return { label: s.name, value: s.name.toString() };
        }),
      },
      {
        label: 'Division',
        options: this.division.map((d) => {
          return { label: d.name, value: d.name.toString() };
        }),
      },
      {
        label: 'Application',
        options: APPS.map((a) => {
          return { label: a.label, value: a.label.toString() };
        }),
      },
    ];
  }

  async searchChanged(event) {
    const label = event.item?.label;
    const value = event.event;
    let filterArr = this.reports;

    if (label === 'Site') {
      this.filter.site = true;
      this.siteFilter = this.filterBySite(value?.value);
    }

    if (label === 'Division') {
      this.filter.division = true;
      this.divisionFilter = this.filterByDivision(value?.value);
    }

    if (label === 'Application') {
      this.filter.application = event.event.value;
      this.applicationFiler = this.filterByApplication(value?.value);
    }

    if (this.filter.site) {
      filterArr = filterArr.filter((obj) => this.siteFilter.includes(obj));
    }

    if (this.filter.division) {
      filterArr = filterArr.filter((obj) => this.divisionFilter.includes(obj));
    }

    if (this.filter.application.length) {
      filterArr = filterArr.filter((obj) =>
        this.applicationFiler.includes(obj)
      );
    }
    this.setReports(filterArr);
  }

  filterByApplication(value: []) {
    const appArray: string[] = [];
    value.map((item: string) => {
      appArray.push(item.toLowerCase());
    });
    const questionByApplication = [];
    this.reports.map(async (item: any, index) => {
      item.applications.map((app) => {
        if (appArray.includes(app.toLowerCase())) {
          questionByApplication.push(this.reports[index]);
        }
      });
    });
    return questionByApplication;
  }

  filterBySite(value: string) {
    const questionBySite = [];
    this.reports.map(async (item: any, index) => {
      item.site.map((app) => {
        if (app === value) {
          questionBySite.push(this.reports[index]);
        }
      });
    });

    return questionBySite;
  }

  filterByDivision(value: string) {
    const questionByDivision = [];
    this.reports.map(async (item: any, index) => {
      item.division.map((app) => {
        if (app === value) {
          questionByDivision.push(this.reports[index]);
        }
      });
    });
    return questionByDivision;
  }

  private filterCustomer(value: string): string[] {
    const filterValue = value.toLowerCase();
    const customers = this.customers.filter((customer) =>
      customer.toLowerCase().includes(filterValue)
    );
    const lowerCustomers = customers.map((customer) => customer.toLowerCase());

    let filterArr = this.reports;
    const filterdCustomers = [];
    this.reports.map(async (item: any, index) => {
      if (lowerCustomers.includes(item.customerName.toLowerCase())) {
        filterdCustomers.push(this.reports[index]);
      }
    });
    filterArr = filterArr.filter((obj) => filterdCustomers.includes(obj));
    this.setReports(filterArr);

    return customers;
  }

  private filterUser(value: string): string[] {
    const filterValue = value.toLowerCase();
    const users = this.users.filter((user) =>
      user.toLowerCase().includes(filterValue)
    );
    const lowerUsers = users.map((customer) => customer.toLowerCase());

    let filterArr = this.reports;
    const filterdUsers = [];
    this.reports.map(async (item: any, index) => {
      if (lowerUsers.includes(item.user?.name.toLowerCase())) {
        filterdUsers.push(this.reports[index]);
      }
    });

    filterArr = filterArr.filter((obj) => filterdUsers.includes(obj));
    this.setReports(filterArr);

    return users;
  }
}
