<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div class="d-md-none"></div>
      <div>{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <form [formGroup]="data.form" (ngSubmit)="data.onSubmit(data)">
      <div class="row inputs">
        <div class="col-12 my-3 p-0">
          <h2 class="m-0 text-normal weight-700">
            {{data?.extraData?.site?.name}}
          </h2>
          <div class="form-group text-normal">
            <p>{{data?.extraData?.question?.text}}</p>
          </div>
        </div>
        <div class="col-12 p-0">
          <div class="form-group mb-3">
            <label class="m-0 mb-1 text-small weight-700" for="">Answer</label>
            <!-- <div class="row"> -->
            <select
              formControlName="answer"
              class="form-control w-25 text-small"
              (ngModelChange)="onEdit()"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="N.R">N.R</option>
              <option value="N.A.">N.A</option>
              <option value=" ">Empty Answer</option>
            </select>
            <!-- <div class="form-group m-2">
                <a formControlName="answer" class="clearAns" (click)=""
                  >clear</a
                >
              </div> -->
            <!-- </div> -->
            <div
              *ngIf="data.form.get('answer').touched && data.form.get('answer').invalid"
              class="alert alert-danger"
            >
              required
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="m-0 mb-1 text-small weight-700" for="">Note</label>
            <textarea formControlName="note" class="form-control" (ngModelChange)="onEdit()"> </textarea>
          </div>
          <div class="form-group mb-3">
            <label class="m-0 mb-1 text-small weight-700" for="">Link</label>
            <input formControlName="link" class="form-control" (ngModelChange)="onEdit()" />
          </div>
          <div class="form-group mb-3">
            <label class="m-0 mb-1 text-small weight-700" for=""
              >Answer Validity</label
            >
            <input
              type="date"
              formControlName="validity_date"
              class="form-control w-50 text-small"
              (ngModelChange)="onEdit()"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-end mb-3">
        <button
          type="submit"
          mat-button
          color="primary"
          class="btn primary text-normal px-5 col-12 col-md-3"
        >
          {{ data.mode === 'view' ? 'Edit Answer' : 'Save' }}
        </button>
      </div>
    </form>
  </div>
</global-dialog-frame>
