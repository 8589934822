import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { GridContent } from '../../../../../components/grid/grid.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogData } from '../../../../products/product/product.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PdfService } from 'src/app/globals/services/pdf.service';
import { ProductService } from 'src/app/globals/services/product.service';
import { Question } from 'src/app/globals/base/question';

export interface stageFourDialog {
  name: string;
  productId: number;
  productName: string;
  mode: 'edit' | 'view';
  extraData?: any;
}

@Component({
  selector: 'stage-four',
  templateUrl: './stage-four.component.html',
  styleUrls: ['./stage-four.component.scss'],
})
export class StageFour implements OnInit {
  routerIdObs$: Observable<string>;
  routerNamrObs$: Observable<string>;
  productId: string;
  pdfData;
  productName;
  isAuthorized:boolean = false;

  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private pdfService: PdfService,
    private productService: ProductService
  ) {
    this.routerIdObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('id');
      })
    );
    this.routerNamrObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('name');
      })
    );
    this.routerIdObs$.subscribe((resId) => {
      this.productId = resId;
    });
    this.routerNamrObs$.subscribe((resName) => {
      this.productName = resName;
    });
  }

  questions: GridContent;
  answerQuestion: FormGroup;
  dialogs: stageFourDialog[];
  edit = 'edit';
  uniqAnswers = [];
  ngOnInit(): void {
    this.update();
  }

  async update() {
    // this.setQuestions();
    this.getQuestionAnswer();
    this.setForms();
    this.setDialogs();

    this.pdfData = await this.pdfService.getPdfData();
    const invalidQuestions = await this.productService.getInvalidAnswers(
      parseInt(this.productId),
      this.pdfData
    );

    if (invalidQuestions.questions.length > 0) {
      this.openDialog();
    }
  }

  private setDialogs() {
    this.dialogs = [
      {
        name: 'Warrning',
        mode: 'edit',
        productId: parseInt(this.productId),
        productName: this.productName,
        extraData: {
          answers: null,
          question: null,
          onContinue: this.downloadPDF.bind(this),
        },
      },
    ];
  }

  async downloadPDF() {
    this.dialog.closeAll();
    const pdfData = await this.pdfService.getPdfData();
    this.productService.exportPDF(parseInt(this.productId), pdfData);
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogStageFour, {
      minWidth: '350px',
      data: this.dialogs[0],
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  private setForms() {
    this.answerQuestion = new FormGroup({
      answer: new FormControl({ value: '0', disabled: true }, [
        Validators.required,
      ]),
      note: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      link: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      answer_validity: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
    });
  }

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }

  private setQuestions(questions: Question[]) {
    this.questions = {
      header: [
        {
          title: 'The question',
          //   col: questions.length,
          col: 8,
        },
        {
          title: 'Action',
          col: 2,
        },
        {
          title: 'Expiration date',
          col: 2,
        },
      ],
      rows: this.setRows(questions),
    };
  }

  setRows(questions: any[]) {
    if (questions.length == 0) {
    }
    // console.log(questions);
    const rows = [];
    questions.forEach((q) => {
      const cols = [
        {
          content: q.question.text,
          col: 8,
          weight: 300,
        },
        {
          content: q.ErrorType,
          color: 'red',
          col: 2,
          weight: 400,
        },
        {
          content:
            q.answer?.validity_date != undefined
              ? this.convertDate(q.answer?.validity_date)
              : '',
          color: 'red',
          col: 2,
          weight: 400,
        },
      ];
      rows.push({ cols: cols });
    });
    return rows;
  }
  convertDate(validity_date: any) {
    let date = new Date(validity_date);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    let dtNew;
    let monthNew;

    if (dt < 10) {
      dtNew = '0' + dt;
    } else {
      dtNew = dt;
    }
    if (month < 10) {
      monthNew = '0' + month;
    } else {
      monthNew = month;
    }
    return dtNew + '-' + monthNew + '-' + year;
  }

  //   private setQuestions() {
  //     this.questions = {
  //       header: [
  //         {
  //           title: 'The question',
  //           col: 10,
  //         },
  //         {
  //           title: 'Action',
  //           col: 2,
  //         },
  //       ],
  //       rows: [
  //         {
  //           // action: this.openEditQuestion.bind(this),
  //           cols: [
  //             {
  //               content:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud?',
  //               col: 10,
  //               weight: 300,
  //             },
  //             {
  //               content: 'Yes',
  //               col: 2,
  //               weight: 400,
  //             },
  //           ],
  //         },
  //         {
  //           // action: this.openEditQuestion.bind(this),
  //           cols: [
  //             {
  //               content:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud?',
  //               col: 10,
  //               weight: 300,
  //             },
  //             {
  //               content: 'Yes',
  //               col: 2,
  //               weight: 400,
  //             },
  //           ],
  //         },
  //         {
  //           // action: this.openEditQuestion.bind(this),
  //           cols: [
  //             {
  //               content:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud?',
  //               col: 10,
  //               weight: 300,
  //             },
  //             {
  //               content: 'Yes',
  //               col: 2,
  //               weight: 400,
  //             },
  //           ],
  //         },
  //         {
  //           // action: this.openEditQuestion.bind(this),
  //           cols: [
  //             {
  //               content:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud?',
  //               col: 10,
  //               weight: 300,
  //             },
  //             {
  //               content: 'Yes',
  //               col: 2,
  //               weight: 400,
  //             },
  //           ],
  //         },
  //         {
  //           // action: this.openEditQuestion.bind(this),
  //           cols: [
  //             {
  //               content:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud?',
  //               col: 10,
  //               weight: 300,
  //             },
  //             {
  //               content: 'Yes',
  //               col: 2,
  //               weight: 400,
  //             },
  //           ],
  //         },
  //         {
  //           // action: this.openEditQuestion.bind(this),
  //           cols: [
  //             {
  //               content:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud?',
  //               col: 10,
  //               weight: 300,
  //             },
  //             {
  //               content: 'Yes',
  //               col: 2,
  //               weight: 400,
  //             },
  //           ],
  //         },
  //       ],
  //     };
  // 	}

  async getQuestionAnswer() {
    this.pdfData = await this.pdfService.getPdfData();
    const invalidQuestions = await this.productService.getInvalidAnswers(
      parseInt(this.productId),
      this.pdfData
    );
    
    this.setQuestions(invalidQuestions.questions);
    // this.questions = invalidQuestions.questions;
    const colsAnswers = invalidQuestions.questions.map((q) => q.ErrorType);
    this.uniqAnswers = [...new Set(colsAnswers.map((c) => c))];
    this.dialogs[0].extraData.answers = this.uniqAnswers;
    // this.dialogs[0].extraData.answers = ['yes', 'no', 'nr', 'ra']
    // this.Questions(invalidQuestions.questions)
  }

  putAnswerQuestion(dialog: DialogData) {
    if (dialog.mode === 'view') {
      dialog.mode = 'edit';
      dialog.form.enable();
    } else {
      dialog.mode = 'view';
      dialog.form.disable();
    }
  }

  async exportPdf() {
    // this.router.navigateByUrl(
    //   `/products/${this.productId}/stage-five/${this.productName}`
    // );
    const pdfData = await this.pdfService.getPdfData();

    this.productService.exportPDF(parseInt(this.productId), pdfData);
  }
}

@Component({
  selector: 'dialog-stage-four',
  templateUrl: './../../dialogs/dialog-stage-four.html',
  styleUrls: ['./../../dialogs/styles/dialog-stage-four.scss'],
})
export class DialogStageFour {
  constructor(
    public dialogRef: MatDialogRef<DialogStageFour>,
    @Inject(MAT_DIALOG_DATA) public data: stageFourDialog,
    private router: Router
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  // onContinue() {
  //   this.onNoClick();
  //   // this.router.navigateByUrl(
  //   //   `/products/${this.data.productId}/stage-five/${this.data.productName}`
  //   // );
  // }
}
