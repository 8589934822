import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProductService } from 'src/app/globals/services/product.service';

@Component({
  selector: 'stage-one',
  templateUrl: './stage-one.component.html',
  styleUrls: ['./stage-one.component.scss'],
})
export class StageOne {
  routerIdObs$: Observable<string>;
  routerNamrObs$: Observable<string>;
  routeFirstAppIdObs$: Observable<string>;
  productId: string;
  productFirstAppId: any;
  productName;

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService
  ) {
    this.routerIdObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('id');
      })
    );
    this.routerNamrObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('name');
      })
    );
    this.routeFirstAppIdObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('firstAppID');
      })
    );
    this.routerIdObs$.subscribe((resId) => {
      this.productId = resId;
    });
    this.routerNamrObs$.subscribe((resName) => {
      this.productName = resName;
    });
    this.routeFirstAppIdObs$.subscribe((resFirstAppID) => {
      this.productFirstAppId = resFirstAppID;
    });
  }

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }

  nextStage() {
    this.router.navigateByUrl(`/products/${this.productId}/stage-two`);
  }
}
