<div class="header-background">
  <img src="../../../../../../assets/sites-header.png" class="w-100 p-0 m-0" />
</div>
<div class="container-fluide p-0 m-0 px-md-5 px-4 py-4 vh-100">
  <div class="px-1 d-none d-md-block">
    <div (click)="back($event)">
      <i
        class="
          cursor-pointer
          text-semi-huge
          color-black-1
          bi bi-arrow-left-short
        "
      ></i>
    </div>
  </div>
  <div class="row d-block d-md-flex h-100">
    <div class="py-2 px-md-5 col-12">
      <div class="row justify-content-between align-items-center">
        <div class="text-huge text-dark font-weight-bold mb-md-4">
          {{divisionName}} Sites List
        </div>
        <button
          (click)="openAddSite()"
          mat-stroked-button
          class="
            font-weight-bold
            btn
            text-normal
            border-primary
            bkg-color-transparent
            color-blue-1
            border-color-blue
          "
        >
          <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
          Add site
        </button>
      </div>
      <global-search-input
        class="d-none d-md-block col-6 p-0"
        (sendSearch)="searchSite($event)"
      ></global-search-input>

      <div>
        <div class="container-fluide p-0 m-0 pb-5">
          <main-grid
            [actionTemplate]="edit"
            [gridContent]="gridContent"
            [authClick]="isAuthorized"
          ></main-grid>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #edit>
  <div class="d-flex justify-around-start align-items-start">
    <mat-icon>edit</mat-icon>
    Edit
  </div>
</ng-template>

<!-- <ng-template #edit >
  <div (click)="handleDelete($event)" class="d-flex justify-around-start align-items-start">
    <mat-icon>delete</mat-icon>
    Delete
  </div>
</ng-template> -->
