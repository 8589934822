import { UsersService } from 'src/app/globals/services/users.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

export const GUARDS = {
  SuperAdminGuard: 'SuperAdminGuard',
  PdfCreatorGuard: 'PdfCreatorGuard',
  AnswerInserterGuard: 'AnswerInserterGuard',
  DivisionManagerGuard: 'DivisionManagerGuard',
  Guest: 'GuestGuard'
};
import { SuperAdminGuard } from './superAdminGuard';
import { PdfCreatorGuard } from './pdfCreatorGuard';
import { DivisionManagerGuard } from './divisionManagerGuard';
import { AnswerInserterGuard } from './answerInserterGuard';
import { GuestGuard } from './guestGuard';

@Injectable()
export class MasterGuard implements CanActivate {
  private route: ActivatedRouteSnapshot;
  private state: RouterStateSnapshot;

  constructor(private usersService : UsersService){}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    this.route = route;
    this.state = state;

    if (!route.data) {
      return Promise.resolve(true);
    }

    if (!this.route.data.guards || !this.route.data.guards.length) {
      return Promise.resolve(true);
    }

    return this.executeGuards();
  }

  //Execute the guards sent in the route data
  private executeGuards(guardIndex: number = 0): Promise<boolean> {
    return this.activateGuard(this.route.data.guards[guardIndex])
      .then((val) => {
        if(val){
          return Promise.resolve(true);
        }
        if (guardIndex < this.route.data.guards.length - 1) {
          return this.executeGuards(guardIndex + 1);
        }
        return Promise.resolve(true);
      })
      .catch((err) => {
        if (guardIndex < this.route.data.guards.length - 1) {
          return this.executeGuards(guardIndex + 1);
        }
        return Promise.reject(false);
      });
  }

  private async activateGuard(guardKey: string): Promise<boolean> {
    let guard:
      | SuperAdminGuard
      | PdfCreatorGuard
      | AnswerInserterGuard
      | DivisionManagerGuard
      | GuestGuard ;

    switch (guardKey) {
      case GUARDS.SuperAdminGuard:
        guard = new SuperAdminGuard(this.usersService);
        break;
      case GUARDS.PdfCreatorGuard:
        guard = new PdfCreatorGuard(this.usersService);
        break;
      case GUARDS.AnswerInserterGuard:
        guard = new AnswerInserterGuard(this.usersService);
        break;
      case GUARDS.DivisionManagerGuard:
        guard = new DivisionManagerGuard(this.usersService);
        break;
      case GUARDS.Guest:
          guard = new GuestGuard(this.usersService);
        break;
      default:
        break;
    }
    return await guard.canActivate(this.route, this.state);
  }
}
