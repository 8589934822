import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, ProductDto } from 'src/app/globals/base/product';
import { ProductService } from 'src/app/globals/services/product.service';
import { GridContent } from '../../../components/grid/grid.component';
import { Question } from 'src/app/globals/base/question';
import { AnswerService } from 'src/app/globals/services/answer.service';
import { Answer, AnswerDto } from 'src/app/globals/base/answer';
import { formatDate } from '@angular/common';
import { APPS, MainService } from '../../../main.service';
import { Site } from 'src/app/globals/base/site';
import { Division } from 'src/app/globals/base/division';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PdfService } from 'src/app/globals/services/pdf.service';
import { SuccessDialogComponent, SuccessDialogModel } from '../../dialog-general/SuccessDialogComponent';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../dialog-general/ConfirmDialogComponent';
import { PermissionsService } from 'src/app/globals/services/permissions.service';
import { GUARDS } from 'src/app/modules/guard/masterGuards';

export interface DialogData {
  name: string;
  form: FormGroup;
  mode: 'edit' | 'view';
  onSubmit?: Function;
  closeDialog?: Function;
  extraData?: any;
}

export interface Questions {
  id: number;
  answer?: Answer;
  question?: Question;
};

@Component({
  selector: 'main-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})

export class MainProduct implements OnInit, OnDestroy {
  subscription = new Subscription();
  sites: Site[] = [];
  divisions: Division[] = [];

  gridContent: GridContent;
  dialogs: DialogData[];
  editProduct: FormGroup;
  answerQuestion: FormGroup;
  product: Product;
  questions: Questions[];
  deleteMode: boolean;
  allQuestions;
  result: string = '';
  routeIdObs$: Observable<string>;
  routeFirstAppIdObs$: Observable<string>;
  productId: string;
  productFirstAppId: any;
  routerExtras:any;
  answer:any;
  isAuthorized:boolean = false;

  constructor(
    public dialog: MatDialog,
    private location: Location,
    private router: Router,
    private productService: ProductService,
    private route: ActivatedRoute,
    private answerService: AnswerService,
    private mainService: MainService,
    private pdfService: PdfService,
    private permissionsService: PermissionsService

  ) {
    this.routerExtras = this.router.getCurrentNavigation();
  }

  ngOnInit() {    
    this.routeIdObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('id');
      })
    );

    this.routeFirstAppIdObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('firstAppID');
      })
    );
    this.routeIdObs$.subscribe((resId) => {
      this.productId = resId;
    });

    this.routeFirstAppIdObs$.subscribe((resFirstAppID) => {
      this.productFirstAppId = resFirstAppID;
    });
    this.update();
  }

  searchQuestions(text) {
    let searchResults = this.allQuestions.filter((row) =>
      row.cols[0].content.toLowerCase().includes(text.toLowerCase())
    );
    this.gridContent.rows = searchResults;
  }

  async setAuthorized(){
    this.isAuthorized = await this.permissionsService.isAuthorized([GUARDS.SuperAdminGuard]);
  }

  async update() {
    try {
      this.subscription = this.mainService.combined$.subscribe(async (obj) => {
        this.sites = obj.s;
        this.divisions = obj.d;
        if (this.sites.length > 0 && this.divisions.length > 0) {
          await this.getData();
          this.setForms();
          this.setDialogs();
          this.setGridContent();
          this.ifRouteParams();
          this.setAuthorized();
        }
      });
    } catch (err) {}
  }

  ifRouteParams(){
    let answerId;
    if(this.routerExtras){
      answerId = this.routerExtras.extras.state?.answerId;
       this.getQuestionById(answerId)
    }
  }

  async getQuestionById (questionId) {
    let currentQuestions = this.questions.filter((item)=> {
      return item.question.id === parseInt(questionId);
    });
    if(currentQuestions && currentQuestions[0]){
      this.openAnswerQuestion(currentQuestions[0].question,currentQuestions[0].answer)
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private async getData() {
    try {
      const id: number = this.route.snapshot.params.id;
      const res = await this.productService.getWithQuestions(id);
      this.product = res.product;
      this.questions = res.questions;
    } catch (err) {}
  }

  private async putProduct() {
    if (!this.deleteMode) {
      try {
        const productDto: ProductDto = new ProductDto({
          name: this.editProduct.controls['product_name'].value,
          site: this.editProduct.controls['product_site'].value
            ? parseInt(this.editProduct.controls['product_site'].value)
            : 0,
          division: this.editProduct.controls['product_division'].value
            ? parseInt(this.editProduct.controls['product_division'].value)
            : 0,
          applications: this.dialogs[0]?.extraData?.apps
            ?.filter((a) => a.value)
            .map((a) => a.id),
        });
        await this.productService.edit(productDto, this.product.id);
        this.closeAll();
        await this.update();
      } catch (err) {
        console.error(err);
      }
    }
  }

  closeAll(): void {
    this.dialog.closeAll();
  }

  openEditProduct(): void {
    this.setDialogs();
    this.setForms();
    this.dialogs[0].form = this.editProduct;
    const dialogRef = this.dialog.open(DialogEditProduct, {
      minWidth: '350px',
      data: this.dialogs[0],
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openAnswerQuestion(this, q, ans): void {
    this.setCurrentQuestion(q, ans);
    const dialogRef = this.dialog.open(DialogAnswerQuestion, {
      minWidth: '350px',
      data: this.dialogs[1],
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  setCurrentQuestion(q: Question, a: Answer) {        
    if (a || q) {
      this.answer = a;
      if(!a) {
        this.answerQuestion.controls['answer'].setValue(' ');
      }
      else {
        this.answerQuestion.controls['answer'].patchValue(a?.selected_option);
        this.answerQuestion.controls['note'].patchValue(a?.text);
        this.answerQuestion.controls['link'].patchValue(a?.link);
      }

      if (a?.validity_date) {
        this.answerQuestion.controls['validity_date'].patchValue(
          formatDate(a?.validity_date, 'yyyy-MM-dd', 'en')
        );
      }

      this.dialogs[1].form = this.answerQuestion;
      this.dialogs[1].extraData.answer = a;
      this.dialogs[1].extraData.question = q;
    } else {      
      this.setForms();
    }
  }

  private setGridContent() {
    this.gridContent = {
      header: [
        {
          title: 'The question',
          col: 5,
        },
        {
          title: 'Chapter',
          col: 3,
        },
        {
          title: 'Notes',
          col: 2,
        },
        {
          title: 'Answer',
          col: 1,
        },

        // {
        //   title: 'Link to',
        //   col: 2,
        // },
        {
          title: '',
          col: 1,
        },
      ],
      rows: [],
    };
    this.questions?.map((q: any) => {
      const { question, answer } = q;
      this.gridContent.rows.push({
        title: question?.text,
        action: this.openAnswerQuestion.bind(this, question, answer),
        cols: [
          {
            key: 'Question',
            content: question?.text,
            col: 5,
            weight: 400,
          },
          {
            key: 'Chapter',
            content: question?.chapter?.title,
            col: 3,
            weight: 400,
          },
          {
            key: 'Notes',
            content: this.getWordStr(answer?.text ? answer?.text : ''),
            col: 2,
            weight: 400,
          },
          {
            key: 'Answer',
            content: answer?.selected_option,
            col: 1,
            weight: 400,
          },
          // {
          //   key: 'Link to',
          //   content: question?.linkTo.map((item, i) => {
          //     return item.name;
          //   }),
          //   col: 2,
          //   weight: 400,
          // },
          {
            key: '',
            content: 'action',
            col: 1,
            weight: 400,
          },
        ],
      });
    });

    this.allQuestions = [...this.gridContent.rows];
  }

  getWordStr(str) {
    // console.log(str);
    return str.split(/\s+/).slice(0, 3).join(' ');
  }

  export() {
    this.pdfService.getStageTwoData('products');

    this.router.navigateByUrl(`/products/${this.productId}/stage-one`);
  }

  async putAnswerQuestion(dialog: DialogData) {
    await this.editQuestionsAnswer();
    // this.closeAll();
    // this.update();
  }

  private closeDialogs() {
    this.dialog.closeAll();
  }

  async editQuestionsAnswer() {    
    try {
      if (
        this.dialogs[1].extraData.question &&
        this.dialogs[1].extraData.question.id
      ) {
        const _answer: Partial<AnswerDto> = {
          validity_date: this.answerQuestion.controls['validity_date'].value,
          text: this.answerQuestion.controls['note'].value,
          link: this.answerQuestion.controls['link'].value,
          selected_option: this.answerQuestion.controls['answer'].value,
          question: this.dialogs[1].extraData?.question?.id,
        };
        if (this.dialogs[1].extraData.answer) {
          if (
            (this.dialogs[1].form.value.answer != ' ' &&
              this.dialogs[1].form.value.answer != null) ||
            (this.dialogs[1].form.value.link != '' &&
              this.dialogs[1].form.value.link != null) ||
            (this.dialogs[1].form.value.note != '' &&
              this.dialogs[1].form.value.note != null)
          ) {
            let ansID = this.dialogs[1].extraData?.answer?.id
              ? this.dialogs[1].extraData?.answer.id
              : null;
            _answer.site = this.product?.site?.id;

            await this.answerService.editProductAnswer(
              this.product.id,
              ansID,
              _answer
            );
            this.closeDialogs();
            this.update();
          } else {
            const message = `You must fill in at least one of the following fields : Answer, Note or Link`;
            const dialogData = new SuccessDialogModel('Error!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
          }
        } else {
          if (
            (this.dialogs[1].form.value.answer != ' ' &&
              this.dialogs[1].form.value.answer != null) ||
            (this.dialogs[1].form.value.link != '' &&
              this.dialogs[1].form.value.link != null) ||
            (this.dialogs[1].form.value.note != '' &&
              this.dialogs[1].form.value.note != null)
          ) {
            let ansID = this.dialogs[1].extraData?.answer?.id
              ? this.dialogs[1].extraData?.answer.id
              : null;
            _answer.site = this.product?.site?.id;

            await this.answerService.editProductAnswer(
              this.product.id,
              ansID,
              _answer
            );
            this.closeDialogs();
            this.update();
          } else {
            const message = `You must fill in at least one of the following fields : Answer, Note or Link`;
            const dialogData = new SuccessDialogModel('Error!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
          }
        }
      } else {
        throw new Error('no question attached');
      }
    } catch (err) {
      console.error(err);
    }
  }

  private setDialogs() {
    const apps = APPS;
    this.dialogs = [
      {
        name: 'Edit Product',
        form: this.editProduct,
        mode: 'edit',
        onSubmit: this.putProduct.bind(this),
        extraData: {
          // apps: apps.map((a, i) => {
          //   a.value = this.product.applications.find((a) => a.id === i + 1)
          //     ? true
          //     : false;
          //   return a;
          // }),
          sites: this.sites,
          divisions: this.divisions,
          changeMode: this.delete.bind(this),
        },
      },
      {
        name: 'Answering Question',
        form: this.answerQuestion,
        mode: 'edit',
        onSubmit: this.putAnswerQuestion.bind(this),
        extraData: {
          answer: null,
          question: null,
        },
      },
    ];
  }

  async delete(status) {
    this.deleteMode = status;
    if (this.deleteMode) {
      const message = `Are you sure you want to delete ${this.product.name} product?`;

      const dialogData = new ConfirmDialogModel('Delete Product', message);

      const dialogConf = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });

      dialogConf.afterClosed().subscribe(async (dialogResult) => {
        this.result = dialogResult;
        if (this.result) {
          try {
            await this.productService.delete(this.product.id);
            this.closeAll();
            const message = `The product is deleted successfully`;
            const dialogData = new SuccessDialogModel('Success!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
            await this.update();
            this.router.navigateByUrl(`/products`);
          } catch (err) {
            const message = `You can not delete the product, this product is linked to other elements`;
            const dialogData = new SuccessDialogModel('Error!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
          }
        }
      });
    }
  }

  private setForms() {
    if (this.product) {
      this.editProduct = new FormGroup({
        product_name: new FormControl(this.product.name, [Validators.required]),
        product_division: new FormControl(this.product.division?.id, [
          Validators.required,
        ]),
        product_site: new FormControl(this.product.site?.id, [
          Validators.required,
        ]),
      });
    }
    this.answerQuestion = new FormGroup({
      answer: new FormControl({ value: null, disabled: false }),
      note: new FormControl({ value: null, disabled: false }),
      link: new FormControl({ value: null, disabled: false }),
      validity_date: new FormControl({ value: null, disabled: false }),
    });
  }

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }
}

@Component({
  selector: 'dialog-edit-product',
  templateUrl: './dialogs/dialog-edit-product.html',
})
export class DialogEditProduct {
  result: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogEditProduct>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.disableClose = true;

    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onNoClick());
  }

  onNoClick(): void {
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      this.result = dialogResult;
      if (this.result) {
        this.dialogRef.close(true);
      }
    });
  }
}

@Component({
  selector: 'dialog-answer-question',
  templateUrl: './dialogs/dialog-answer-question.html',
})
export class DialogAnswerQuestion {
  result: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogAnswerQuestion>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.disableClose = true;

    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onNoClick());
  }

  onNoClick(): void {
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      this.result = dialogResult;
      if (this.result) {
        this.dialogRef.close(true);
      }
    });
  }
}
