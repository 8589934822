<div class="d-flex flex-column justify-content-between main-wrapper">
  <div class="d-flex flex-column">
    <div class="text-normal">
      <mat-nav-list>
        <a
          *ngFor="let route of routes"
          matLine
          [routerLink]="route.value"
          routerLinkActive="active"
          (click)="removeLocalStorage()"
        >
          <mat-list-item class="font-family-assi px-2" *ngIf='route.canLoad'>
            {{ route.label }}
          </mat-list-item>
        </a>
      </mat-nav-list>
    </div>
  </div>
  <div class="px-0 py-3 d-flex footer">
    <mat-nav-list>
      <mat-list-item class="font-family-assi">
        <img class="icon-1 mx-2" src="/assets/user.svg" alt="" />
        {{userName}}
      </mat-list-item>
      <mat-list-item class="font-family-assi">
        <a (click)="logout($event)">
          <img class="icon-1 mx-2" src="/assets/exit.svg" alt="" />
          Logout
        </a>
      </mat-list-item>
    </mat-nav-list>
  </div>
</div>
