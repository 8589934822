<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div class="d-md-none"></div>
      <div class="">{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <form [formGroup]="data.form" (ngSubmit)="data.onSubmit(data)">
      <div class="row inputs">
        <div class="d-flex justify-content-between col-12 my-3 p-0">
          <h2 class="m-0 text-normal weight-700">Question</h2>
          <ng-container *ngIf="data.mode === 'view'">
            <button
              (click)="data.onSubmit(data)"
              mat-stroked-button
              color="primary"
              class="text-normal d-none d-md-block"
            >
              <mat-icon>edit</mat-icon>
              Edit
            </button>
          </ng-container>
        </div>
        <div class="col-12 p-0">
          <div class="form-group mb-3">
            <textarea
              formControlName="text"
              class="form-control enabled-grey text-tiny"
              (ngModelChange)="onEdit()"
            >
              {{data?.form.text}}</textarea
            >

            <div
              *ngIf="data.form.get('text').touched && data.form.get('text').invalid && data?.extraData.invalidQuestion"
              class="alert alert-danger"
            >
              Question is require
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <hr />
        </div>
        <div class="d-flex justify-content-between col-12 my-0 my-md-2 p-0">
          <h2 class="m-0 text-normal weight-700">
            The question is linked to categories:
          </h2>
        </div>
        <div class="col-12 my-2 p-0">
          <!-- <div class="mb-3">
            <div class="m-0 mb-2 text-small weight-600">
              Site ({{data?.extraData?.sites?.length}})
            </div>
            <main-pills
              class="text-tiny"
              [options]="data?.extraData?.sitePillsOptions"
              [(pills)]="data?.extraData.sites"
              [isEdit]="data?.mode === 'edit'"
            >
            </main-pills>
          </div> -->
          <div class="mb-3">
            <div class="m-0 mb-2 text-small weight-600">
              Apps ({{data?.extraData?.filterApps(data?.extraData?.apps)}})
            </div>
            <main-checkboxes
              class="text-tiny"
              [(checkboxes)]="data?.extraData.apps"
              [isEdit]="data?.mode === 'edit'"
              (isChange)= "onEdit()"
            >
            </main-checkboxes>
          </div>
        </div>
        <div class="d-flex justify-content-between col-12 my-2 p-0">
          <ng-container *ngIf="data?.mode === 'view'; else noneView">
            <h2 class="m-0 text-normal weight-700">
              Linked to:
              <span class="weight-400 text-small ml-2 ml-md-0">
                {{data?.form.value.chapter?.title}} -
                {{data?.form.value.subchapter?.title}}
              </span>
            </h2>
          </ng-container>
          <ng-template #noneView>
            <div class="w-100 form-group mb-3">
              <div class="m-0 mb-2 text-normal weight-700">Link To Chapter</div>
              <div class="d-flex row">
                <div class="col-12 col-md-4 p-0 text-small">
                  <select
                    class="form-control"
                    formControlName="chapter"
                    (change)="data?.extraData.onChaperSelect($event , data)"
                    (ngModelChange)="onEdit()"
                  >
                    <option
                      *ngFor="let c of data?.extraData.chapters;"
                      [value]="c.id"
                    >
                      {{c?.title}}
                    </option>
                  </select>
                </div>
                <div
                  class="col-12 col-md-4 p-0 my-2 my-md-0 mx-md-2 text-small"
                >
                  <select class="form-control" formControlName="subchapter"
                  (ngModelChange)="onEdit()"
                  >
                    <option
                      *ngFor="let sc of data?.extraData.subchapters;"
                      [value]="sc.id"
                    >
                      {{sc.title}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="data?.mode === 'view'">
          <div class="m-0 mb-3 d-md-none text-normal weight-700">
            <u>Edit Links</u>
          </div>
        </ng-container>
      </div>
      <div class="d-flex justify-content-end dialog-btns">
        <ng-container *ngIf="data?.mode === 'view'">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12 col-md-3 d-md-none"
          >
            Add question
          </button>
        </ng-container>
        <ng-container *ngIf="data?.mode !== 'view'">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12 col-md-4 btn primary mx-1"
          >
            Save
          </button>
        </ng-container>
        <ng-container *ngIf="data?.name == 'Edit Question'">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12 col-md-4 mx-1"
            (click)="data?.extraData.changeMode(true)"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Delete icon"
              class="delete-icon"
              >delete</mat-icon
            >
            Delete Question
          </button>
        </ng-container>
      </div>
    </form>
  </div>
</global-dialog-frame>
