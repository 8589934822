import { Base } from "./Base";
import { Chapter } from "./chapter";
import { Question } from "./question";

export class Subchapter extends Base{

    id: number;
    title: string;
    position: number;
    created_date: Date;
    updated_date: Date;
    chapter: Chapter;
    questions: Question[];

    constructor(args: Partial<Subchapter>) {
        super(args)
    }
    
}
