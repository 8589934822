import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'auth-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.scss'],
})
export class AuthPostLogin {
  constructor(
    private router: Router,
    private adalService: AdalService,
    private http: HttpClient
  ) {
    this.adalService.init(environment.adalConfig);
    this.adalService.handleWindowCallback();
  }
  ngOnInit() {
    // this.update();
    this.adalService.acquireToken(this.adalService.config.loginResource).toPromise()
    .then(token => {
      this.adalService.userInfo.token = token;
      if (this.adalService.userInfo.authenticated === false) {
        this.adalService.userInfo.authenticated = true;
        this.adalService.userInfo.error = '';
        
      }
      
      this.router.navigateByUrl('');
    }).catch(error => {
      this.adalService.userInfo.authenticated = false;
      this.adalService.userInfo.error = error;
      this.router.navigate(['auth/login']);
    });
  }

  async update() {
    console.log('authenticated');

    if (this.adalService && this.adalService.userInfo.authenticated) {
      // const extraData = await this.getMe().toPromise();
      // console.log('extraData',extraData);

      // if (extraData) {
      //   localStorage.setItem(
      //     'extraData',
      //     JSON.stringify({
      //       givenName: (extraData as any).givenName,
      //       surname: (extraData as any).surname,
      //       mobilePhone: (extraData as any).mobilePhone,
      //     })
      //   );
      // }
      this.router.navigateByUrl('');
    } else {
      localStorage.removeItem('extraData');
      this.router.navigate(['auth/login']);
    }
  }

  getMe<T>() {
    return this.adalService.acquireToken('https://graph.microsoft.com').pipe(
      mergeMap((token) => {
        const headersParam = new HttpHeaders({
          'Content-Type': 'application/json;odata=verbose',
          Authorization: 'Bearer ' + token.toString(),
        });
        return this.http.get('https://graph.microsoft.com/v1.0/me', {
          headers: headersParam,
        });
      })
    );
  }
}
