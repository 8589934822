import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { GridContent } from '../../../components/grid/grid.component';
import { DialogData } from '../../products/product/product.component';
import { PdfLinkService } from 'src/app/globals/services/pdf-link.service';
import {PdfLink} from 'src/app/globals/base/pdf-link';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../dialog-general/ConfirmDialogComponent';

@Component({
  selector: 'app-links-to-pdf',
  templateUrl: './links-to-pdf.component.html',
  styleUrls: ['./links-to-pdf.component.scss'],
})
export class LinksToPdfComponent implements OnInit {
  dialogs: DialogData[];
  links: GridContent;
  pdfLink: PdfLink[] = [];
  currentPdfLink: PdfLink;
  editLink: FormGroup;
  addLink: FormGroup;
  editText: FormGroup;
  addText: FormGroup;
  deleteMode: boolean;
  isAuthorized:boolean = false;

  constructor(
    private dialog: MatDialog,
    private pdfLinkService: PdfLinkService
    ) {}

  ngOnInit() {
    this.update();
  }

  async update() {
    await this.getLink();
    this.setLinks();
    this.setForms();
    this.setDialogs();
  }

  async getLink() {
    this.pdfLink = await this.pdfLinkService.getAll()
  }

  private setLinks() {
    this.links = {
      header: [
        {
          col: 7,
          title: 'title',
        },
        {
          col: 3,
          title: 'link',
        },
        {
          col: 2,
          title: '',
        },
      ],
      rows: []
    };

    this.pdfLink?.map((link) =>
    this.links.rows.push({
      title: link.title,
      action: this.openEditLink.bind(this,link),
      cols: [
        {
          key: 'title',
          content: link?.title,
          col: 7,
          weight: 300,
        },
        {
          key: 'link',
          content: link?.link,
          col: 4,
          weight: 300,
        },
        {
          key: '',
          content: 'action',
          col: 1,
          weight: 300,
        },
      ],
    })
  );
  }

  isValid = (dialog: DialogData) => {
    return dialog.form.status == "VALID"
  }

  async editPdfLink(dialog: DialogData) {
    try{
      if (!this.deleteMode) {
      await this.pdfLinkService.edit(this.currentPdfLink.id, dialog.form.value);
      this.closeDialog();
      this.update()
      }
    }catch(err){
      console.log(err);
    }
  }

  async addPdfLink(dialog: DialogData) {
    try{
      await this.pdfLinkService.add(dialog.form.value);
      this.closeDialog();
      this.update()
    }
    catch(err){
      console.log(err);
    }
  }

  async editPdfText(dialog: DialogData) {
    console.log("editPdfText");
  }

  async addPdfText(dialog: DialogData) {
    console.log("addPdfText");
  }

  async delete(status) {
    this.deleteMode = status;

    if(status){
      const message = `Are you sure you want to delete ${this.currentPdfLink.title} Link?`;
      console.log('message',message);

      const dialogData = new ConfirmDialogModel('Delete Link', message);
      console.log('dialogData',dialogData);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe(async (dialogResult) => {
         if (dialogResult) {
           try{
            await this.pdfLinkService.delete(this.currentPdfLink.id);
            this.closeDialog();
            this.update();
           }
           catch(err){
             console.log(err);
           }
         }
      });
    }
  }

  private setDialogs() {
    this.dialogs = [
      {
        name: 'Add Text',
        form: this.addText,
        mode: 'edit',
        onSubmit: this.addPdfText.bind(this),
        extraData: {title:"Title",text:"Link"},
      },
      {
        name: 'Edit Text',
        form: this.editText,
        mode: 'edit',
        onSubmit: this.editPdfText.bind(this),
        extraData: {title:"Title",text:"Link"},
      },
      {
        name: 'Add Link',
        form: this.addLink,
        mode: 'edit',
        onSubmit: this.addPdfLink.bind(this),
        extraData: {title:"Title",text:"Link"},
      },
      {
        name: 'Edit Link',
        form: this.editLink,
        mode: 'edit',
        onSubmit: this.editPdfLink.bind(this),
        extraData: {
          changeMode: this.delete.bind(this),
          title:"Title",
          text:"Link",
        },
      },
    ];
  }

  private setForms() {
    this.editLink = new FormGroup({
      title: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required]),
    });
    this.addLink = new FormGroup({
      title: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required]),
    });

    this.editText = new FormGroup({
      title: new FormControl('', [Validators.required]),
      text: new FormControl('', [Validators.required]),
    });

    this.addText = new FormGroup({
      title: new FormControl('', [Validators.required]),
      text: new FormControl('', [Validators.required]),
    });
  }

  openEditLink(link) {
    this.currentPdfLink = link;
    this.editPdfLinkForm(link)
    const dialogRef = this.dialog.open(DialogEditLink, {
      minWidth: '350px',
      data: this.dialogs[3],

    });
  }

  openAddLink() {
    const dialogRef = this.dialog.open(DialogAddLink, {
      minWidth: '350px',
      data: this.dialogs[2],

    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  private editPdfLinkForm(p: PdfLink) {
    this.dialogs[3].form = new FormGroup({
      title: new FormControl({ value: p?.title, disabled: false }, [
        Validators.required,
      ]),
      link: new FormControl({ value: p?.link, disabled: false }, [
        Validators.required,
      ]),
    });
  }

}

@Component({
  selector: 'dialog-edit-link',
  templateUrl: './../dialogs/dialog-edit-link.html',
})
export class DialogEditLink {
  constructor(
    public dialogRef: MatDialogRef<DialogEditLink>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        this.dialogRef.close(true);
      }
    });
  }
}

@Component({
  selector: 'dialog-add-link',
  templateUrl: './../dialogs/dialog-add-link.html',
})
export class DialogAddLink {
  constructor(
    public dialogRef: MatDialogRef<DialogAddLink>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        this.dialogRef.close(true);
      }
    });
  }
}


