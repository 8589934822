<global-dialog-frame>
  <div global-dialog-title class="w-100">
    <div
      class="
        p-0
        d-flex
        text-normal-med
        justify-content-between
        align-items-center
      "
    >
      <div></div>
      <div class="">{{data.name}}</div>
      <i class="bi bi-x-lg cursor-pointer" (click)="onNoClick()"></i>
    </div>
  </div>
  <div global-dialog-content class="dialog-content">
    <ng-container *ngIf="data.mode === 'edit' ; else view">
      <form [formGroup]="data.form" (ngSubmit)="data.onSubmit(data)">
        <div class="row">
          <div class="row m-0 p-0 w-100 my-2">
            <div class="col-12 col-md-6 p-0">
              <h3 class="m-0 text-normal font-weight-bold">Name</h3>
              <div class="form-group">
                <input
                  formControlName="name"
                  type="text"
                  class="form-control text-small"
                  placeholder="user name"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-2 p-0">
            <h3 class="m-0 text-normal font-weight-bold">Email</h3>
            <div class="form-group">
              <input
                formControlName="email"
                type="text"
                class="form-control text-small"
                placeholder="mymail@mymail.com"
              />
            </div>
          </div>
          <div class="col-12 mb-2 p-0">
            <h3 class="m-0 text-normal font-weight-bold">Division link</h3>
            <div class="form-group mb-3">
              <select class="form-control text-small">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>
          <div class="col-12 mb-2 p-0">
            <h3 class="m-0 text-normal font-weight-bold">Site link</h3>
            <div class="form-group mb-3">
              <select class="form-control text-small">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-2 p-0">
            <h3 class="m-0 text-normal font-weight-bold">Permission</h3>
            <div class="form-group mb-3">
              <select class="form-control text-small">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row justify-content-end mb-3">
          <button
            type="submit"
            mat-button
            color="primary"
            class="btn primary text-normal col-12 col-md-3"
          >
            Save
          </button>
        </div>
      </form>
    </ng-container>
    <ng-template #view>
      <form [formGroup]="data.form" (ngSubmit)="data.onSubmit(data)">
        <div class="row" style="max-width: calc(100% - 29px)">
          <div class="col-12 p-0 my-3">
            <div class="row m-0 p-0">
              <div class="row">
                <img
                  src="../../../../../../assets/user1.svg"
                  alt=""
                  style="width: 12px"
                />
                <div
                  style="height: 100%; width: 1.5px; background: #0ea9cb"
                  class="mx-2"
                ></div>
              </div>
              <div class="col-6 col-md-5 m-0 p-0">
                <h3 class="m-0 font-weight-bold text-normal">Name</h3>
                <div class="">
                  <span class="text-small">{{data.form.name}}</span>
                </div>
              </div>
              <div class="col-6 m-0 p-0 justify-content-end d-none d-md-flex">
                <button
                  type="submit"
                  mat-stroked-button
                  color="primary"
                  class="text-normal"
                  style="height: 36px"
                >
                  <img src="../../../../../../assets/edit.svg" alt="" />
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 mb-3">
            <div class="row m-0 p-0">
              <div class="row">
                <img
                  src="../../../../../../assets/email.svg"
                  alt=""
                  style="width: 12px"
                />
                <div
                  style="height: 100%; width: 1.5px; background: #0ea9cb"
                  class="mx-2"
                ></div>
              </div>
              <div class="col-6 m-0 p-0">
                <h3 class="m-0 font-weight-bold text-normal">Email</h3>
                <div class="">
                  <span class="text-small">{{data.form.email}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 mb-3" style="margin-left: 29px">
            <h3 class="m-0 font-weight-bold text-normal">Division link</h3>
            <div class="text-small">
              <span *ngFor="let division of data.extraData.divisions"
                >{{division.name}},
              </span>
            </div>
          </div>
          <div class="col-12 p-0 mb-3" style="margin-left: 29px">
            <h3 class="m-0 font-weight-bold text-normal">Site link</h3>
            <div class="mx-1">
              <span *ngFor="let site of data.extraData.sites"
                >{{site.name}},
              </span>

            </div>
          </div>
          <div class="col-12 p-0 mb-4" style="margin-left: 29px">
            <h3 class="m-0 font-weight-bold text-normal">Permissions</h3>
            <div class="mx-1">
              <span *ngFor="let permission of data.extraData.permissions"
                >{{permission.name}},
              </span>

            </div>
          </div>
        </div>
        <div class="col-6 m-0 p-0 justify-content-end d-flex d-md-none col-12">
          <button
            type="submit"
            mat-stroked-button
            color="primary"
            class="text-normal col-12"
            style="height: 36px"
          >
            <!-- <div class="col-3 d-flex justify-content-between"> -->
            <img src="../../../../../../assets/edit.svg" alt="" />
            <span class="ml-2"> Edit </span>
            <!-- </div> -->
          </button>
        </div>
      </form>
    </ng-template>
  </div>
</global-dialog-frame>
