<div class="search-wrapper d-flex my-3 w-100">
  <input
    [placeholder]="placeholder"
    class="search-input"
    type="text"
    [(ngModel)]="search"
    (keyup)="handelChange()"
  />
  <button mat-button class="btn primary text-normal p-0 weight-700">
    <i class="bi bi-search"></i>
  </button>
</div>
