import { Base } from './Base';
import { Pdf } from './pdf';
import { Product } from './product';
import { Site } from './site';

export class Division extends Base {
  id: number;
  name: string;
  pdf_text: string;
  created_date: Date;
  updated_date: Date;
  products: Product[];
  sites: Site[];
  pdfs: Pdf[];

  constructor(args: Partial<Division>) {
    super(args);
  }
}

export class DivisionDto extends Base {
  id: number;
  name: string;
  pdf_text: string;
  products: number[];
  sites: number[];
  pdfs: number[];

  constructor(args: Partial<Division>) {
    super(args);
  }
}
