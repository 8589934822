import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ChapterService } from 'src/app/globals/services/chapter.service';
import { DivisionService } from 'src/app/globals/services/division.service';
import { SiteService } from 'src/app/globals/services/site.service';
import { MainService } from './main.service';
import { mainRouter } from './routes/animations';

@Component({
  templateUrl: './main.component.html',
  selector: 'main',
  styleUrls: ['./main.component.scss'],
  animations: [mainRouter],
})
export class MainComponent implements OnInit {

  opened: boolean = true;
  isMobile: boolean;

  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private siteService: SiteService,
    private divisionService: DivisionService,
    private chapterService: ChapterService,
    private mainService: MainService
  ) { }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnInit() {
    this.update();
  }

  async update() {
    this.router.events.subscribe((val) => {
      if (this.isMobile && this.opened) this.toggleSideBar();
    });
    this.isMobile = this.deviceService.isMobile();
    if (this.isMobile) this.opened = false;

    await this.getDivisions();
    await this.getSites();
    await this.getChapters();
  }

  async getDivisions() {
    try {
      const divisions = await this.divisionService.getAll();
      this.mainService.setDivisions(divisions)
    } catch (err) {

    }
  }

  async getChapters() {
    try {
      const chapters = await this.chapterService.getAll();
      this.mainService.setChapters(chapters)
    } catch (err) {

    }
  }

  async getSites() {
    try {
      const sites = await this.siteService.getAll();
      this.mainService.setSites(sites)
    } catch (err) {

    }
  }

  toggleSideBar() {
    this.opened = !this.opened;
  }

  showRouterOutler() {
    if (this.isMobile) {
      if (this.opened) {
        return false;
      }
      return true;
    }
    return true;
  }
}
