import { Subchapter } from './../base/subchapter';
import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';
import { Chapter } from '../base/chapter';
import { ChapterPosition } from 'src/app/modules/main/routes/chapters/chapters.component';
import { SubchaptersPosition } from 'src/app/modules/main/routes/chapters/chapter/chapter.component';

@Injectable({
  providedIn: 'root',
})
export class ChapterService {
  constructor(private apiService: IclSmartQApi) { }


  async getAll(): Promise<Chapter[]> {
    try {
      
      const res = await this.apiService.get('/chapters');
      const _chapters: Chapter[] = res.map(c => new Chapter(c));
      return _chapters;
    } catch (err) {
      throw err;
    }
  }

  async get(id: number): Promise<Chapter> {
    try {
      
      const res = await this.apiService.get('/chapters/' + id);
      const _chapter: Chapter = new Chapter(res);
      return _chapter;
    } catch (err) {
      throw err;
    }
  }


  async getChaptersCount(): Promise<Chapter> {
    try {
      
      return await this.apiService.get('/chapters/count');
    } catch (err) {
      throw err;
    }
  }

  async editChapterPosition(chapter: Partial<ChapterPosition>): Promise<Chapter> {
    try {
      
      return await this.apiService.put('/chapters/positionUpdate',{ body: {...chapter} } );
    } catch (err) {
      throw err;
    }
  }

  async editSubchaptersPosition(subchapters: Partial<SubchaptersPosition>): Promise<Chapter> {
    try {
       const res =  await this.apiService.put('/subchapters/positionUpdate',{ body: {...subchapters} } );
       return res

    } catch (err) {
      throw err;
    }
  }

  async add(chapter: Partial<Chapter>): Promise<Chapter> {
    try {
      
      const res = await this.apiService.post('/chapters', { body: {...chapter} });
      const _chapter: Chapter = new Chapter(res);
      return _chapter;
    } catch (err) {
      throw err;
    }
  }
  async addSubchapters(chapter: Partial<Chapter>): Promise<Subchapter> {
    try {
      
      const res = await this.apiService.post('/subchapters', { body: {...chapter} });
      const _chapter: Subchapter = new Subchapter(res);
      return _chapter;
    } catch (err) {
      throw err;
    }
  }

  async editSubchapters(id: number,chapter: Partial<Chapter>): Promise<Subchapter> {
    try {
      
      const res = await this.apiService.put('/subchapters/' + id, { body: {...chapter} });
      const _chapter: Subchapter = new Subchapter(res);
      return _chapter;
    } catch (err) {
      throw err;
    }
  }

  async edit(id: number, chapter: Partial<Chapter>): Promise<Chapter> {
    try {
      
      const res = await this.apiService.put('/chapters/' + id, {
        body: { ...chapter },
      });
      const _chapter: Chapter = new Chapter(res);
      return _chapter;
    } catch (err) {
      throw err;
    }
  }

  async delete(id: number): Promise<Chapter> {
    try {
      
      const res = await this.apiService.delete('/chapters/' + id, {});
      const _chapter: Chapter = new Chapter(res);
      return _chapter;
    } catch (err) {
      throw err;
    }
  }

  async deleteSubChapter(id: number): Promise<Chapter> {
    try {
      
      const res = await this.apiService.delete(`/subchapters/${id}`, {});
      const _chapter: Chapter = new Chapter(res);
      return _chapter;
    } catch (err) {
      throw err;
    }
  }
}
