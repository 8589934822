import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PdfService } from 'src/app/globals/services/pdf.service';
import { ProductService } from 'src/app/globals/services/product.service';
import { ComponentCanDeactivate } from 'src/app/modules/main/directives/component-can-deactivate';
import { Email } from 'src/app/modules/main/components/chip-input/chip-input.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
  },
};

interface ValidEmail {
  accounts: Email[];
  valid: Boolean;
}
@Component({
  selector: 'stage-two',
  templateUrl: './stage-two.component.html',
  styleUrls: ['./stage-two.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class StageTwo extends ComponentCanDeactivate implements OnInit {
  productDetails: FormGroup;
  stageTwoForm: FormGroup;
  routerIdObs$: Observable<string>;
  routerFirstAppIDObs$: Observable<string>;

  productId: string;
  productFirstAppID;
  subscription: Subscription;
  date = new Date(new Date().getTime());
  stageTwoData;
  emptyCompanyName = false;
  emptyCompanySite = false;
  emptyContactPerson = false;
  // emptyApplicationEmail = false;
  product: any;
  email: ValidEmail = {
    accounts: [],
    valid: true,
  };

  constructor(
    private location: Location,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private pdfService: PdfService,
    private productService: ProductService
  ) {
    super();
    this.routerIdObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('id');
      })
    );
    this.routerFirstAppIDObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('firstAppID');
      })
    );
    this.routerIdObs$.subscribe(async (resId) => {
      this.productId = resId;
      this.product = await this.productService.get(parseInt(this.productId));
    });

    this.routerFirstAppIDObs$.subscribe((resApp) => {
      this.productFirstAppID = resApp;
    });

    this.stageTwoData = this.pdfService.getStageTwoData('stageTwo');

    this.setForm();
  }

  canDeactivate(): boolean {
    return true;
  }

  setForm() {
    this.stageTwoForm = new FormGroup({
      companyName: new FormControl(
        this.stageTwoData ? this.stageTwoData.companyName : '',
        Validators.required
      ),
      companySite: new FormControl(
        this.stageTwoData ? this.stageTwoData.companySite : '',
        Validators.required
      ),
      contactPerson: new FormControl(
        this.stageTwoData ? this.stageTwoData.contactPerson : '',
        Validators.required
      ),
      distributor: new FormControl(
        this.stageTwoData ? this.stageTwoData.distributor : ''
      ),
      applicationEmail: new FormControl(
        this.stageTwoData ? this.stageTwoData.applicationEmail : '',
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ),
      //   productName: new FormControl('', Validators.required),
      issuingDate: new FormControl(
        this.stageTwoData ? this.stageTwoData.issuingDate : this.date,
        Validators.required
      ),
      // Applications: new FormControl('', Validators.required),
    });
  }

  onSubmit(form: FormGroup) {
    this.email.valid = true;
    this.emptyCompanyName = false;
    this.emptyCompanySite = false;
    this.emptyContactPerson = false;
    // this.emptyApplicationEmail = false;
    if (form.invalid) {
      if (form.value.companyName == '') {
        this.emptyCompanyName = true;
      }
      if (form.value.companySite == '') {
        this.emptyCompanySite = true;
      }
      if (form.value.contactPerson == '') {
        this.emptyContactPerson = true;
      }
      // if (this.email.accounts.length === 0) {
      // //  this.emptyApplicationEmail = true;
      // }
      return;
    }
    if (this.email.accounts.length === 0) {
      // this.emptyContactPerson = true;
      // return;
    }
    this.emptyCompanyName = false;
    this.emptyCompanySite = false;
    this.emptyContactPerson = false;
    // this.emptyApplicationEmail = false;
    form.value.productName = this.product.name;
    form.value.applicationEmail = this.email.accounts;
    this.pdfService.setStageTwoData(form.value);
    this.nextStage();
  }

  ngOnInit(): void {}

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }

  updateInputValue(text, index: number) {}

  nextStage() {
    // this.pdfService.getStageThreeData('stageTwo');

    // this.router.navigateByUrl(`/products/${this.productId}/stage-three`);
    this.router.navigateByUrl(
      encodeURI(`/products/${this.productId}/stage-four/${this.product.name}`)
    );
  }

  setDetail(detail) {
    return this.fb.group({
      label: detail.label,
      value: detail.value,
    });
  }

  details = [
    {
      controlName: 'companyName',
      label: 'Customer Name*',
      value: 'text',
    },
    {
      controlName: 'companySite',
      label: 'Customer Site*',
      value: 'text',
    },
    {
      controlName: 'contactPerson',
      label: 'Contact Person*',
      value: 'text',
    },
    {
      controlName: 'distributor',
      label: 'Distributor Name',
      value: 'text',
    },
    {
      controlName: 'applicationEmail',
      label: 'Send To Email',
      value: 'text',
    },
    // {
    //   controlName: 'productName',
    //   label: 'Product Name*',
    //   value: 'text',
    // },
    {
      controlName: 'issuingDate',
      label: 'Issuing Date*',
      value: 'text',
    },
    // {
    //   controlName: 'Applications',
    //   label: 'Applications*',
    //   value: 'text',
    // },
  ];

  changeEmail(event) {
    this.email = event;
    // this.emptyApplicationEmail = false;
    if (this.email.accounts.length > 0) {
      setTimeout(() => {
        this.email.valid = true;
      }, 4000);
    }
  }
}
