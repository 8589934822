import { Answer } from './answer';
import { Application } from './application';
import { Base } from './Base';
import { Division } from './division';
import { Pdf } from './pdf';
import { Site } from './site';

export class Product extends Base {
  id: number;
  name: string;
  division: Division;
  site: Site;
  applications: Application[];
  answers: Answer[];
  pdfs: Pdf[];
  questions: [];
  chosenApp: any;

  constructor(args: Partial<Product>) {
    super(args);
  }
}

export class ProductDto extends Base {
  name: string;
  division: number;
  site: number;
  applications: [];
  answers: string;
  pdfs: string;
  id: number;
  needsToCopy?: boolean;
  copyFromProduct?: number;

  constructor(args: Partial<ProductDto>) {
    super(args);
  }
}
