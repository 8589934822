<div class="grid">
  <mat-list role="list">
    <ng-container>
      <mat-list-item role="listitem">
        <div class="container-fluid p-0">
          <div class="row pb-3">
            <div class="col-10">hy</div>
            <div class="col-2" (click)="toggleDropdown()">
              <img
                src="../../../../../assets/arrow-down.png"
                alt=""
                *ngIf="!dropdown"
              />
              <img
                src="../../../../../assets/arrow-up.svg"
                alt=""
                *ngIf="dropdown"
              />
            </div>
          </div>
          <div class="row" *ngIf="dropdown">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div class="font-weight-bold">Answer</div>
                <div>yes</div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="font-weight-bold">Link to</div>
                <div>Site 2</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12 weight-500 px-3 d-flex align-items-center"></div>
          </div>
        </div> -->
      </mat-list-item>
    </ng-container>
  </mat-list>
</div>

<div class="container-fluid">
  <div class="row my-5 justify-content-center">
    <pagination-controls
      (pageChange)="p = $event"
      [previousLabel]="null"
      [nextLabel]="null"
    ></pagination-controls>
  </div>
</div>
