import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { UsersService } from 'src/app/globals/services/users.service';

@Injectable({ providedIn: 'root' })
export class AnswerInserterGuard implements CanActivate {
  constructor(private userService: UsersService) {}

  // let property = "";
  // setProperty(s) {
  //   this.property = s;
  // }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const user = await this.userService.getCurrentUser();
      return new Promise((resolve: Function, reject: Function) => {
        //why do you need true/false here if you are using resolve/reject?  It's okay but seems weird
        if (user.permissions.answer_inserter) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    } catch (err) {
      console.error('user', err);
    }
  }
}
