import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';
import { LoaderService } from './loader.service';

interface ApiRequest {
  withCredentials?: boolean;
  headers?: HttpHeaders;
  params?: any;
  body?: any;
  responseType?: any;
}

interface ApiResponse {
  ok: boolean;
  records?: any;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class IclSmartQApi {
  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private adalService: AdalService
  ) {}

  async get(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ): Promise<any> {
    
    options.withCredentials = false;
    options.headers = this.prepareOptions();
    return await this.parseRequest(
      this.http.get(`${environment.baseUrl + ''}${url}`, options).toPromise(),
      showLoader
    );
  }

  async put(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ): Promise<any> {
    
    options.withCredentials = false;
    options.headers = this.prepareOptions();
    return await this.parseRequest(
      this.http
        .put(`${environment.baseUrl}${url}`, options.body, options)
        .toPromise(),
      showLoader
    );
  }

  async post(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ): Promise<any> {
    
    options.withCredentials = false;
    options.headers = this.prepareOptions();    
    return await this.parseRequest(
      this.http
        .post(`${environment.baseUrl}${url}`, options.body, options)
        .toPromise(),
      showLoader
    );
  }

  async importProduct(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ): Promise<any> {
    options.withCredentials = false;
    options.headers = this.prepareOptions();  
      
    return await this.parseRequest(
      this.http
        .post(`${environment.baseUrl}${url}`, options.body, options)
        .toPromise(),
      showLoader
    );
  }

  async DownloadPDF(
    url: string,
    options: ApiRequest = {},
    showLoader: boolean = true
  ) {
    options.withCredentials = false;
    options.headers = this.prepareOptions();
    options.responseType = 'blob';
    
    return this.parseRequest(
      this.http
        .post(`${environment.baseUrl}${url}`, options.body,options)
        .toPromise(),
      showLoader
    );
  }

  async delete(
    url: string,
    options: ApiRequest,
    showLoader: boolean = true
  ): Promise<any> {
    
    options.withCredentials = false;
    options.headers = this.prepareOptions();
    return await this.parseRequest(
      this.http.delete(`${environment.baseUrl}${url}`, options).toPromise(),
      showLoader
    );
  }

  private async parseRequest(
    req: Promise<any>,
    showLoader: boolean = true
  ): Promise<any> {
    try {
      
      if (showLoader) this.loaderService.setShowLoader(true);
      const response: ApiResponse = await req;
      if (showLoader) this.loaderService.setShowLoader(false);
      if (!response.error) return response.records || response;
      throw new Error(response.error);
    } catch (err) {
      if (showLoader) this.loaderService.setShowLoader(false);
      throw new Error(err);
    }
  }

  private prepareOptions(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', ['application/json'])
      .set('Authorization', `Bearer ${this.adalService.userInfo.token}`);
      
    return headers;
  }

  private showLoader(): void {
    this.loaderService.setShowLoader(true);
  }
}
