import { Component, EventEmitter, Input, Output } from "@angular/core";


export interface Pill {
    value: number | string,
    label: string
}

@Component({
    selector: "main-pills",
    templateUrl: './pills.component.html',
    styleUrls: ['./pills.component.scss']
})

export class MainPill {

    @Input() pills: Pill[];
    @Output() pillsChange = new EventEmitter();

    @Input() options: Pill[] = [
        {
            value: 1,
            label: 'Site 01'
        },
        {
            value: 2,
            label: 'Site 02'
        },
        {
            value: 3,
            label: 'Site 03'
        }
    ]

    @Input() isEdit: boolean = true;

    opened: boolean = false

    remove(pill: Pill) {
        this.pills = this.pills.filter(p => pill.value !== p.value)
        this.options.push(pill);
        this.pillsChange.emit(this.pills);
        this.opened = false;
    }

    toggle() {
		if (this.options) {
			this.opened = !this.opened;
		}

    }

    add(pill: Pill) {
        this.pills.push(pill);
        this.options = this.options.filter(p => pill.value !== p.value);
        this.pillsChange.emit(this.pills);
        this.opened = false;
    }

}