import { Component, Inject, OnInit } from '@angular/core';
import { GridContent } from '../../../components/grid/grid.component';
import { formatDate, Location } from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogData } from '../../products/product/product.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Site } from 'src/app/globals/base/site';
import { SiteService } from 'src/app/globals/services/site.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Question } from 'src/app/globals/base/question';
import { AnswerService } from 'src/app/globals/services/answer.service';
import { Answer, AnswerDto } from 'src/app/globals/base/answer';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../dialog-general/ConfirmDialogComponent';
import { SuccessDialogComponent, SuccessDialogModel } from '../../dialog-general/SuccessDialogComponent';

@Component({
  selector: 'main-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class MainSite implements OnInit {
  site: Site;
  questions: GridContent;
  dialogs: DialogData[];
  answerQuestion: FormGroup;
  allQuestion;
  routerExtras:any;
  isAuthorized:boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private siteService: SiteService,
    private answerService: AnswerService
  ) {
    this.routerExtras = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    this.update();
  }

  async loadAllData() {
    try {
      const id = this.route.snapshot.params.id;
      this.site = await this.siteService.get(id);
    } catch (err) {
      console.error(err);
    }
  }

  async update() {
    try {
      await this.loadAllData();
      this.setQuestions();
      this.setForms();
      this.setDialogs();
      this.ifRouteParams();
    } catch (err) {}
  }

  private setDialogs() {
    this.dialogs = [
      {
        name: 'Answering Question2',
        form: this.answerQuestion,
        mode: 'edit',
        onSubmit: this.putQuestionsAnswer.bind(this),
        extraData: {
          answer: null,
          question: null,
          site: this.site,
        },
      },
    ];
  }

  private setForms() {
    this.answerQuestion = new FormGroup({
      answer: new FormControl({ value: null, disabled: false }, [
        Validators.required,
      ]),
      note: new FormControl({ value: null, disabled: false }, [
        Validators.required,
      ]),
      link: new FormControl({ value: null, disabled: false }, [
        Validators.required,
      ]),
      validity_date: new FormControl({ value: null, disabled: false }, [
        Validators.required,
      ]),
    });
  }

  private setQuestions() {
    try {
      this.questions = {
        header: [
          {
            title: 'The question',
            col: 4,
          },
          {
            title: 'Chapter',
            col: 2,
          },
          {
            title: 'Answer',
            col: 4,
          },
          {
            title: 'Selected',
            col: 1,
          },
          {
            title: '',
            col: 1,
          },
        ],
        rows: [],
      };
      this.site.questions?.map((q) => {

        this.questions.rows.push({
          title: q.question.text,
          object: q,
          action: this.openEditQuestion.bind(this, q?.question, q?.answer),
          cols: [
            {
              key: 'The question',
              content: q?.question?.text,
              col: 4,
              weight: 300,
            },
            {
              key: 'Chapter',
              content: q?.question.chapter?.title,
              col: 2,
              weight: 400,
            },
            {
              key: 'Answer',
              content: q?.answer?.text,
              col: 4,
              weight: 400,
            },
            {
              key: 'Selected',
              content: q?.answer?.selected_option,
              col: 1,
              weight: 400,
            },
            {
              content: 'action',
              col: 1,
              weight: 400,
            },
          ],
        });
      });
      this.allQuestion = [...this.questions.rows];
    } catch (err) {
      console.error(err);
    }
  }

  searchQuestion(text) {
    let searchResults = this.allQuestion.filter((row) =>
      row.cols[0].content.toLowerCase().includes(text.toLowerCase())
    );
    this.questions.rows = searchResults;
  }

  openEditQuestion(question: Question, answer: Answer) {
    this.setCurrentQuestion(question, answer);

    const dialogRef = this.dialog.open(DialogEditSiteQuestion, {
      minWidth: '350px',
      data: this.dialogs[0],
       autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('DialogEditSiteQuestion');
    });
  }

  private closeDialogs() {
    this.dialog.closeAll();
  }

  setCurrentQuestion(q: Question, a: Answer) {        
    if (a) {
      if (a.selected_option === '' || !a.selected_option) {
        this.answerQuestion.controls['answer'].setValue(' ');
      } else {
        this.answerQuestion.controls['answer'].patchValue(a.selected_option);
      }
      this.answerQuestion.controls['note'].patchValue(a.text);
      this.answerQuestion.controls['link'].patchValue(a.link);
      this.answerQuestion.controls['validity_date'].patchValue(
        a.validity_date? formatDate(a.validity_date, 'yyyy-MM-dd', 'en') :null
      );
      this.dialogs[0].form = this.answerQuestion;
    } else {
      this.setForms();
      this.answerQuestion.controls['answer'].setValue(' ');
      this.dialogs[0].form = this.answerQuestion;
    }

    this.dialogs[0].extraData.answer = a;
    this.dialogs[0].extraData.question = q;
  }

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }

  async putQuestionsAnswer(dialog: DialogData) {
    if (dialog.mode === 'view') {
      dialog.mode = 'edit';
      dialog.form.enable();
    } else {
      await this.editQuestionsAnswer();

      // dialog.mode = 'view';
      // dialog.form.disable()
    }
  }

  async editQuestionsAnswer() {
    try {
      if (
        this.dialogs[0].extraData.question &&
        this.dialogs[0].extraData.question.id
      ) {
        const _answer: Partial<AnswerDto> = {
          validity_date: this.dialogs[0].form.controls['validity_date'].value,
          text: this.dialogs[0].form.controls['note'].value,
          link: this.dialogs[0].form.controls['link'].value,
          selected_option: this.dialogs[0].form.controls['answer']?.value,
          question: this.dialogs[0].extraData.question.id,
          site: this.site.id,
        };
        console.log('answer -> ', _answer);
        if (this.dialogs[0].extraData.answer) {
          if (
            (this.dialogs[0].form.value.answer != ' ' &&
              this.dialogs[0].form.value.answer != null) ||
            (this.dialogs[0].form.value.link != '' &&
              this.dialogs[0].form.value.link != null) ||
            (this.dialogs[0].form.value.note != '' &&
              this.dialogs[0].form.value.note != null)
          ) {
            (_answer.id = this.dialogs[0].extraData.answer.id),
              await this.answerService.editSiteAnswers(_answer.id, _answer);
            this.closeDialogs();
            this.update();
          } else {
            const message = `You must fill in at least one of the following fields : Answer, Note or Link`;
            // const dialogData = new AnswerDialogModel('Error!', message);
            // this.dialog.open(AnswerDialogComponent, {
            //   maxWidth: '400px',
            //   data: dialogData,
            // });
            const dialogData = new SuccessDialogModel('Error!', message);
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
          }
        } else {
          if (
            (this.dialogs[0].form.value.answer != ' ' &&
              this.dialogs[0].form.value.answer != null) ||
            (this.dialogs[0].form.value.link != '' &&
              this.dialogs[0].form.value.link != null) ||
            (this.dialogs[0].form.value.note != '' &&
              this.dialogs[0].form.value.note != null)
          ) {
            // await this.answerService.add(_answer);
            await this.answerService.createSiteQuestions(_answer);
            this.closeDialogs();
            this.update();
          } else {
            const message = `You must fill in at least one of the following fields : Answer, Note or Link`;
            // const dialogData = new AnswerDialogModel('Error!', message);
            const dialogData = new SuccessDialogModel('Error!', message);
            // this.dialog.open(AnswerDialogComponent, {
            //   maxWidth: '400px',
            //   data: dialogData,
            // });
            this.dialog.open(SuccessDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
          }
        }
      } else {
        throw new Error('no question attached');
      }
    } catch (err) {
      console.error(err);
    }
  }

  ifRouteParams(){
    let answerId;
    if(this.routerExtras){
       answerId = this.routerExtras.extras.state.answerId;
       this.getQuestionById(answerId)
    }
  }

  async getQuestionById (questionId) {
    let currentQuestions = this.site.questions.filter((item)=> {
      return item.question.id === parseInt(questionId);
    });
    if(currentQuestions && currentQuestions[0]){
      this.openEditQuestion(currentQuestions[0].question,currentQuestions[0].answer)
    }
  }
}

@Component({
  selector: 'dialog-edit-site-question',
  templateUrl: './dialogs/dialog-edit-site-question.html',
})
export class DialogEditSiteQuestion {
  result: string = '';
  isEdit = false;

  constructor(
    public dialogRef: MatDialogRef<DialogEditSiteQuestion>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.disableClose = true;

    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onNoClick());
  }
  onEdit(): void {
    this.isEdit = true
  }

  onNoClick(): void {
    if(!this.isEdit){
      this.dialogRef.close(true);
      return;
    }
    this.isEdit = false;

    const message = `Are you sure you want to exit without saving changes?`;
    const dialogData = new ConfirmDialogModel('Exit without saving', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      this.result = dialogResult;
      if (this.result) {
        this.dialogRef.close(true);
      }
    });
  }





}
