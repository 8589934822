import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DivisionService } from 'src/app/globals/services/division.service';
import { ProductService } from 'src/app/globals/services/product.service';
import { Product } from 'src/app/globals/base/product';
import { PdfService } from 'src/app/globals/services/pdf.service';
import { SiteService } from 'src/app/globals/services/site.service';
import { APPS, MainService } from 'src/app/modules/main/main.service';

export interface Option {
  name: string;
  id: number;
}

@Component({
  selector: 'stage-three',
  templateUrl: './stage-three.component.html',
  styleUrls: ['./stage-three.component.scss'],
})
export class StageThree implements OnInit {
  routerIdObs$: Observable<string>;
  routerFirstAppIDObs$: Observable<string>;

  productId: string;
  divisions: Option[] = [];
  sites: Option[] = [];
  apps: Option[] = [];
  products: Product[] = [];
  defaultDiv;
  product: Product;
  productFirstApp;
  productFirstAppID;
  stageThreeData;
  div: FormGroup;
  divL;
  appL;
  firstApp;
  appsDropDown: Option[] = [];
  divsDropDown: Option[] = [];
  pdfData: {
    company_name: any;
    company_site: any;
    contact_person: any;
    distributor: any;
    applicant_email: any;
    product_name: any;
    issuing_date: any;
    division: any;
    applications: any[];
  };

  constructor(
    private location: Location,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private divisionService: DivisionService,
    private mainService: MainService,
    private productService: ProductService,
    private pdfService: PdfService,
    private siteService: SiteService
  ) {
    this.routerIdObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('id');
      })
    );
    this.routerFirstAppIDObs$ = this.route.paramMap.pipe(
      switchMap((params) => {
        return params.getAll('firstAppID');
      })
    );
    this.routerIdObs$.subscribe((resId) => {
      this.productId = resId;
    });
    this.routerFirstAppIDObs$.subscribe((resApp) => {
      this.productFirstAppID = resApp;
      APPS.map((a) => {
        if (a.id == this.productFirstAppID) {
          this.productFirstApp = a.label;
        }
      });
      console.log(this.productFirstApp);
    });

    this.stageThreeData = this.pdfService.getStageThreeData('stageThree');
    this.setOptionsLists();

    this.firstApp = {
      name: this.productFirstApp != 'undefined' ? this.productFirstApp : null,
      id: this.productFirstAppID,
    };
    this.setForm();
  }
  stageThree: FormGroup;
  ngOnInit(): void {
    // this.setForm();
    // 	window.addEventListener('unload', (e) => {
    // 	  this.router.navigateByUrl('')
    //   })
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: Event) {}

  back(e: Event): void {
    e.preventDefault();
    this.location.back();
  }

  setForm() {
    this.divL = this.stageThreeData ? this.stageThreeData.divisionLink : null;
    this.appL = this.stageThreeData
      ? this.stageThreeData.appLink
      : this.firstApp;

    this.stageThree = this.fb.group({
      divisionLink: [null, [Validators.required]],
      appLink: [null, [Validators.required]],
    });
  }

  onSubmit(form: FormGroup) {
    if (form.value.divisionLink == null) {
      form.value.divisionLink = this.stageThreeData
        ? this.stageThreeData.divisionLink
        : null;
    }
    if (form.value.appLink == null) {
      form.value.appLink = this.stageThreeData
        ? this.stageThreeData.appLink
        : this.firstApp;
    }

    if (form.value.divisionLink == null || form.value.appLink == null) return;
    this.pdfService.setStageThreeData(form.value);
    this.nextStage();
  }

  async setOptionsLists() {
    try {
      //get product
      this.product = await this.productService.get(parseInt(this.productId));
      //get product's applications
      this.apps = this.product.applications.map((app) => {
        return {
          name: app.name,
          id: app.id,
        };
      });
      for (let i = 0; i < this.apps.length; i++) {
        if (this.apps[i].name != this.appL.name) {
          this.appsDropDown.push({
            name: this.apps[i].name,
            id: this.apps[i].id,
          });
        }
      }

      console.log(this.appsDropDown);
      //get all divisions and sites
      await this.mainService.combined$.subscribe((obj) => {
        this.divisions = obj.d.map((div) => {
          return {
            name: div.name,
            id: div.id,
          };
        });
        for (let i = 0; i < this.divisions.length; i++) {
          if (this.divL && this.divisions[i].name != this.divL.name) {
            this.divsDropDown.push({
              name: this.divisions[i].name,
              id: this.divisions[i].id,
            });
          }
        }
        this.sites = obj.s.map((site) => {
          return {
            name: site.name,
            id: site.id,
          };
        });
      });
      this.defaultDiv = this.product.division.name;
    } catch (err) {
      throw err;
    }
  }

  getdivisionsOptions() {
    return this.divsDropDown.length > 0 ? this.divsDropDown : this.divisions;
  }

  getSitesOptions() {
    return this.sites;
  }

  getApplicationsOptions() {
    return this.appsDropDown;
  }

  async nextStage() {
    this.router.navigateByUrl(
     encodeURI(`/products/${this.productId}/stage-four/${this.product.name}/${this.productFirstApp}`) 
    );
  }

  details = [
    {
      controlName: 'divisionLink',
      label: 'Link to Division',
      value: 'text',
    },
    {
      controlName: 'appLink',
      label: 'Link to App',
      value: 'text',
    },
  ];
}
