import { animate, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { LoaderService } from "../../services/loader.service";

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    animations: [
        trigger(
            'fadeInOut', [
            transition(
                ':enter',
                [
                    style({ opacity: 0 }),
                    animate('0.2s ease-out',
                        style({ opacity: 1 }))
                ]
            ),
            transition(
                ':leave',
                [
                    style({ opacity: 1 }),
                    animate('0.2s ease-in',
                        style({ opacity: 0 }))
                ]
            )]
        )]
})
export class AppLoaderComponent implements OnInit {

    showLoader: boolean = false;

    constructor(private loaderService: LoaderService) { }

    ngOnInit(): void {
        this.loaderService.showLoader$.subscribe(s => this.showLoader = s)
    }

}