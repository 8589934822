import { SiteDto } from 'src/app/globals/base/site';
import { IclSmartQApi } from './api.service';
import { Injectable } from '@angular/core';
import { Site } from '../base/site';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(private apiService: IclSmartQApi) {}

  async get(id: number): Promise<Site> {
    try {
      const res = await this.apiService.get('/sites/' + id);
      const _site: Site = new Site(res);
      return _site;
    } catch (err) {
      throw err;
    }
  }

  async getAll(): Promise<Array<Site>> {
    let _sites: Site[];
    try {
      // if (_sites.length === 0) {
      const records = await this.apiService.get('/sites');
      _sites = records?.map((s) => new Site(s));
      return _sites;
      // } else {
      //   return _sites;
      // }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async add(site: Partial<SiteDto>): Promise<Site> {
    try {
      const res = await this.apiService.post('/sites', { body: site });
      const _site: Site = new Site(res);
      return _site;
    } catch (err) {
      throw err;
    }
  }

  async edit(id: number, site: Partial<SiteDto>): Promise<Site> {
    try {
      const res = await this.apiService.put(`/sites/` + id, {
        body: site,
      });
      const _site: Site = new Site(res);
      return _site;
    } catch (err) {
      throw err;
    }
  }

  async delete(id: number): Promise<Site> {
    try {
      const res = await this.apiService.delete('/sites/' + id, {});
      const _site: Site = new Site(res);
      return _site;
    } catch (err) {
      throw err;
    }
  }
}
